/* eslint-disable */
import React, { Component } from "react";
import axios from "axios";
import cookie from "react-cookies";
import { connect } from "react-redux";
import update from "immutability-helper";
import Slider from "react-slick";
import moment from "moment";
import ReactTooltip from "react-tooltip";

import {
  appId,
  apiUrl,
  apiUrlV2,
  apiUrlCtrng,
  deliveryId,
  pickupId,
  cateringId,
  reservationId,
} from "../Helpers/Config";
import {
  getReferenceID,
  showLoader,
  hideLoader,
  stripslashes,
  showCustomAlert,
  showCartItemCount,
} from "../Helpers/SettingHelper";
import {
  GET_MENU_NAVIGATION,
  GET_CATERINGCART_DETAIL,
  GET_CATERING_PRODCUTDETAIL,
  GET_CATERING_PRODCUTLIST,
} from "../../actions";
const isEqual = require("react-fast-compare");

import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Productlist from "./SubCompo/Productlist";
import Eventdatetime from "./SubCompo/Eventdatetime";
// import Packagedetails from "./SubCompo/Packagedetails";
import Productdetails from "./SubCompo/Productdetails";
import Ordersummary from "./SubCompo/Ordersummary";
import CantDlyImg from "../../common/images/Cant_Deliver.png";
import fbscootersvg from "../../common/images/delivery.svg";
import iconUnhappy from "../../common/images/sad-smiley.png";
import iconWin from "../../common/images/icon-win-b.svg";
var qs = require("qs");

class Catering extends Component {
  constructor(props) {
    super(props);
    this.state = {
      commonTabCls: "resp-tab-item",
      categoryActCls: "active cat_cur-active",
      venueDetActCls: "",
      packageActCls: "",
      checkoutActCls: "",
      catering_date: "",
      catering_time: "",
      breaktime_enable: "No",
      breaktime_count: 1,
      breaktime_list: [],
      break_timeHtml: "",
      cartItems: [],
      cartDetails: [],
      cartTotalItmCount: 0,
      cateVenueFlag: "",
      catePrimaryId: "",
      category_id: "",
      category_name: "",
      subcategory_id: "",
      catering_outlet_id: "",
      catering_hall_id: "",
      catering_product_id: "",
      cart_outlet_id: "",
      cart_outlet_txt: "",
      cart_outlet_unitNum: "",
      cart_hall_id: "",
      cart_hall_txt: "",
      trigger_venue_submit: "No",
      trigger_checkout_submit: "No",
      active_tabflag: 1,
      add_new_item: "No",
      overAllCateringCart: [],
      cateringCartItems: [],
      cateringCartDetails: [],
      cateringCartTotalItmCount: 0,
      trigger_venue_type: "No",
      venue_type_Initail: "",
      orderDeliveryAddress: "",
      cateringOutlet: [],
      productsdetail: [],
    };

    cookie.save("defaultAvilablityId", cateringId, { path: "/" });

    var avilablityIdTxt = cookie.load("defaultAvilablityId");
    if (avilablityIdTxt === deliveryId || avilablityIdTxt === pickupId) {
      this.props.history.push("/products");
      return false;
    }

    if (avilablityIdTxt === reservationId) {
      this.props.history.push("/reservation");
      return false;
    }

    var venueTypeInitail =
      typeof cookie.load("venueTypeInitail") !== "undefined"
        ? cookie.load("venueTypeInitail")
        : "";
    this.state["venue_type_Initail"] = venueTypeInitail;

    cookie.save("cartTotalItems", 0);
    cookie.remove("dateTimeTrigger");
    this.cateringOutlets();
    this.props.getCateringCartDetail();
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.cateringCartItems !== nextProps.cateringCartItems) {
      var cartDetailsMain = nextProps.cateringCartDetails;
      if (Object.keys(cartDetailsMain).length > 0) {
        cookie.save("cartTotalItems", nextProps.cateringCartTotalItmCount);
        if (cartDetailsMain.cart_breaktime_enable === "Yes") {
          var cartBrkLst =
            cartDetailsMain.cart_breaktime_list !== "" &&
            cartDetailsMain.cart_breaktime_list !== null
              ? JSON.parse(cartDetailsMain.cart_breaktime_list)
              : [];
          this.setState({
            breaktime_enable: cartDetailsMain.cart_breaktime_enable,
            breaktime_count: cartDetailsMain.cart_breaktime_count,
            breaktime_list: cartBrkLst,
          });
        }
      }

      this.setState(
        {
          cartTotalItmCount: nextProps.cateringCartTotalItmCount,
          cartDetails: cartDetailsMain,
          cartItems: nextProps.cateringCartItems,
          overAllCateringCart: nextProps.overAllCateringCart,
          cateringCartTotalItmCount: nextProps.cateringCartTotalItmCount,
          cateringCartDetails: cartDetailsMain,
          cateringCartItems: nextProps.cateringCartItems,
        },
        function () {
          this.getSingleCartProduct();
        }.bind(this)
      );
    }

    if (this.props.productData != nextProps.productData) {
      if (
        nextProps.productData.result_set &&
        nextProps.productData.status == "ok"
      ) {
        this.setState({ productsdetail: nextProps.productData });
        $(".no-recrds-found").hide();
      } else {
        $(".no-recrds-found").show();
      }
    }

    setTimeout(function () {
      if ($(".input-focus").length > 0) {
        $(".input-focus").focus(function () {
          $(this).parents(".focus-out").addClass("focused");
        });
        $(".input-focus").blur(function () {
          var inputValue = $(this).val();
          if (inputValue == "") {
            $(this).removeClass("filled");
            $(this).parents(".focus-out").removeClass("focused");
          } else {
            $(this).addClass("filled");
          }
        });
      }
    }, 400);
  }

  getSingleCartProduct() {
    var cartItems = this.state.cartItems;
    if (Object.keys(cartItems).length > 0) {
      var crtProductId = cartItems[0].cart_item_product_id;
      if (crtProductId !== "") {
        axios
          .get(
            apiUrlV2 +
              "catering/cateringproducts_detail?app_id=" +
              appId +
              "&product_id=" +
              crtProductId +
              "&availability=" +
              cateringId +
              "&status=A&"
          )
          .then((res) => {
            var proResultset = Array();
            if (res.data.status === "success") {
              if (Object.keys(res.data.result_set).length > 0) {
                proResultset = res.data.result_set[0];
                cookie.save(
                  "catering_categoryPryId",
                  proResultset.pro_cate_primary_id
                );
                cookie.save(
                  "catering_categoryId",
                  proResultset.product_category_id
                );
                cookie.save(
                  "catering_categoryName",
                  stripslashes(proResultset.catgory_name)
                );
                this.setState({
                  catePrimaryId: proResultset.pro_cate_primary_id,
                  category_id: proResultset.product_category_id,
                  category_name: stripslashes(proResultset.catgory_name),
                });
              }
            }
          });
      }

      var cartDetailsArr = this.state.cartDetails;
      var outletId = cartDetailsArr.outlet_id;
      var hallId = cartDetailsArr.cart_hall_id;
      var hallChrg = cartDetailsArr.cart_hall_charges;
      var eventtypeTxt = cartDetailsArr.cart_venue_type;
      var venueTypeInitail = cookie.load("venueTypeInitail");
      var eventtype = "";
      if (outletId !== "" && eventtypeTxt === "hall") {
        axios
          .get(
            apiUrlV2 +
              "catering/cateringHalls?app_id=" +
              appId +
              "&hall_id=" +
              hallId
          )
          .then((res) => {
            var seletedHallId = "";
            if (res.data.status === "ok") {
              var resultData = res.data.result_set;

              if (resultData.length == 1) {
                seletedHallId =
                  resultData[0].outlet_id +
                  "~" +
                  stripslashes(resultData[0].outlet_name) +
                  "~" +
                  resultData[0].catering_hall_setup_id +
                  "~" +
                  stripslashes(resultData[0].catering_hall_title) +
                  "~" +
                  resultData[0].catering_hall_postal_code +
                  "~" +
                  stripslashes(resultData[0].catering_hall_address) +
                  "~" +
                  hallChrg +
                  "~" +
                  resultData[0].catering_hall_pax;
              }
            }

            this.setState({
              cateVenueFlag: "hall",
              venue_type_Initail: "indoor",
              cart_hall_id: hallId,
              cart_hall_txt: seletedHallId,
              catering_outlet_id: outletId,
              catering_hall_id: hallId,
            });
          });
        venueTypeInitail = "indoor";
        eventtype = "hall";
      } else if (outletId !== "") {
        axios
          .get(
            apiUrlV2 +
              "outlets/getAllOutles?app_id=" +
              appId +
              "&availability_id=" +
              cateringId +
              "&outlet_id=" +
              outletId
          )
          .then((res) => {
            var seletedOutletId = "";
            var unitNum = "";
            if (res.data.status === "ok") {
              var resultData = res.data.result_set;

              if (resultData.length == 1) {
                if (
                  resultData[0].outlet_unit_number1 !== "" ||
                  resultData[0].outlet_unit_number2 !== ""
                ) {
                  unitNum =
                    resultData[0].outlet_unit_number2 !== ""
                      ? " #" +
                        resultData[0].outlet_unit_number1 +
                        "-" +
                        resultData[0].outlet_unit_number2
                      : " #" + resultData[0].outlet_unit_number1;
                }
                seletedOutletId =
                  resultData[0].oa_outlet_id +
                  "~" +
                  resultData[0].outlet_postal_code +
                  "~" +
                  stripslashes(resultData[0].outlet_name) +
                  "~" +
                  stripslashes(resultData[0].outlet_address_line1);
              }
            }

            this.setState({
              cateVenueFlag: "venue",
              venue_type_Initail: "outdoor",
              cart_outlet_id: outletId,
              cart_outlet_txt: seletedOutletId,
              cart_outlet_unitNum: unitNum,
              catering_outlet_id: outletId,
            });
          });
        venueTypeInitail = "outdoor";
        eventtype = "venue";
      }

      cookie.save("venueTypeInitail", venueTypeInitail);
      cookie.save("catering_eventType", eventtype);
    }
  }

  cateringOutlets() {
    axios
      .get(
        apiUrl +
          "outlets/pickup_outlets?app_id=" +
          appId +
          "&availability_id=" +
          cateringId
      )
      .then((res) => {
        if (res.data.status === "ok") {
          if (Object.keys(res.data.result_set).length > 0) {
            var cateringOutlet = res.data.result_set[0];
            this.setState({ cateringOutlet: cateringOutlet });
          }
        }
      });
  }

  eventTypePopupMain() {
    cookie.remove("orderTime");
    cookie.remove("orderTimeEnd");
    $.magnificPopup.open({
      items: {
        src: "#venue-popup",
      },
      type: "inline",
      showCloseBtn: false,
      closeOnBgClick: false,
    });
  }

  changeEvtType(eventtype) {
    //   if(this.state.venue_type_Initail !== eventtype) {
    // 	    var totalItems = cookie.load('cartTotalItems');
    // 		if(parseInt(totalItems)>0) {
    // 			$.magnificPopup.open({
    // 				items: {
    // 					src: '#clearpopupid'
    // 				},
    // 				type: 'inline'
    // 			});

    // 		} else {
    // 			this.resetEventType(eventtype,'no');
    // 		}
    //   } else {
    // 	    $.magnificPopup.close();
    //   }

    $.magnificPopup.close();
    this.setState({ venue_type_Initail: eventtype });
    if (eventtype === "outdoor") {
      var popupIdtxt = "#catering-postcode-popup";
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    } else {
      var popupIdtxt = "#catering-inhouse-popup";
      $.magnificPopup.open({
        items: {
          src: popupIdtxt,
        },
        type: "inline",
      });
    }
  }

  resetEventType(eventtype, trigger) {
    var tempVl = "";
    cookie.save("catering_eventType", tempVl);
    cookie.save("catering_categoryPryId", tempVl);
    cookie.save("catering_categoryId", tempVl);
    cookie.save("catering_categoryName", tempVl);

    cookie.save("venueTypeInitail", eventtype);

    this.setState(
      {
        venue_type_Initail: eventtype,
        cateVenueFlag: tempVl,
        catePrimaryId: tempVl,
        category_id: tempVl,
        category_name: tempVl,
        venueTypeChanged: "No",
      },
      function () {
        $.magnificPopup.close();
        if (trigger === "yes") {
          window.location.reload();
        }
      }.bind(this)
    );
  }

  cartDestoryAndRstEvntFun() {
    var eventtype =
      this.state.venue_type_Initail === "indoor" ? "outdoor" : "indoor";

    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlV2 + "cateringcart/destroy", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          cookie.save("fromVenueFlg", "Yes");
          showCustomAlert("success", "Nice! Your cart is empty");
          var cartDetails = Array();
          showCartItemCount(cartDetails);
          this.resetEventType(eventtype, "yes");
        } else {
          $.magnificPopup.close();
          showCustomAlert("error", "Sorry! Products can`t update your cart");
        }
      })
      .catch(function (error) {
        console.log(error);
        showCustomAlert("error", "something went wrong");
        $.magnificPopup.close();
      });
  }

  setActiveTab = (tabid) => {
    var userId = cookie.load("UserId");
    var catgProId = cookie.load("catering_product_id");
    var eventType = cookie.load("catering_eventType");
    var OutletId = cookie.load("cateringOutletId");
    var HallId = cookie.load("cateringHallId");
    var totalItems = cookie.load("cartTotalItems");
    var order_date = cookie.load("order_date");
    var orderTime = cookie.load("orderTime");
    var orderTimeEnd = cookie.load("orderTimeEnd");

    var catering_product_id = this.state.catering_product_id;
    var selectedProId = catgProId === "" ? catgProId : catering_product_id;

    var categoryAct = "";
    var venueDetAct = "";
    var packageAct = "";
    var checkoutAct = "";

    if (tabid === 1) {
      categoryAct = "active cat_cur-active";
    } else if (tabid === 2) {
      if (typeof selectedProId !== "undefined" && selectedProId !== "") {
        categoryAct = "active";
        venueDetAct = "active cat_cur-active";
      } else {
        tabid = 1;
        categoryAct = "active cat_cur-active";
      }
    } else if (tabid === 3) {
      if (
        typeof selectedProId !== "undefined" &&
        selectedProId !== "" &&
        typeof order_date !== "undefined" &&
        order_date !== ""
      ) {
        if (typeof orderTime === "undefined" || orderTime === "") {
          tabid = 2;
          $(".start_time_error").show();
          categoryAct = "active";
          venueDetAct = "active cat_cur-active";
        } else if (typeof orderTimeEnd === "undefined" || orderTimeEnd === "") {
          tabid = 2;
          $(".end_time_error").show();
          categoryAct = "active";
          venueDetAct = "active cat_cur-active";
        } else {
          categoryAct = "active";
          venueDetAct = "active";
          packageAct = "active cat_cur-active";
        }
        // if((this.state.catering_outlet_id === OutletId) && (this.state.catering_hall_id === HallId)) {
        // categoryAct = 'active'; venueDetAct = 'active'; packageAct = 'active';
        // } else {
        // 	tabid = 2;
        // 	categoryAct = 'active'; venueDetAct = 'active';
        // 	this.setState({trigger_venue_submit: 'Yes'});
        // }
      } else if (typeof selectedProId !== "undefined" && selectedProId !== "") {
        tabid = 2;
        $(".order_date_error").show();
        categoryAct = "active";
        venueDetAct = "active cat_cur-active";
      } else {
        tabid = 1;
        categoryAct = "active cat_cur-active";
      }
    } else if (tabid === 4) {
      if (
        typeof selectedProId !== "undefined" &&
        selectedProId !== "" &&
        typeof order_date !== "undefined" &&
        order_date !== "" &&
        parseInt(totalItems) > 0
      ) {
        // if(typeof userId !== 'undefined' && userId !== '') {
        categoryAct = "active";
        venueDetAct = "active";
        packageAct = "active";
        checkoutAct = "active cat_cur-active";
        // } else {
        // 	tabid = 1;
        // 	categoryAct = 'active';
        // 	this.setState({trigger_checkout_submit: 'Yes'});
        // 	cookie.save('fromCkeckOutVld', 'Yes');

        // }
      } else if (
        typeof selectedProId !== "undefined" &&
        selectedProId !== "" &&
        typeof order_date !== "undefined" &&
        order_date !== ""
      ) {
        if (this.state.catering_outlet_id === OutletId) {
          tabid = 3;
          categoryAct = "active";
          venueDetAct = "active";
          packageAct = "active cat_cur-active";
          if (parseInt(totalItems) === 0) {
            showCustomAlert("error", "Sorry! Your cart is empty.");
          }
        } else {
          tabid = 2;
          categoryAct = "active";
          venueDetAct = "active cat_cur-active";
          this.setState({ trigger_venue_submit: "Yes" });
        }
      } else if (typeof selectedProId !== "undefined" && selectedProId !== "") {
        tabid = 2;
        categoryAct = "active";
        venueDetAct = "active cat_cur-active";
      } else {
        tabid = 1;
        categoryAct = "active cat_cur-active";
      }
    }

    this.setState({
      active_tabflag: tabid,
      categoryActCls: categoryAct,
      venueDetActCls: venueDetAct,
      packageActCls: packageAct,
      checkoutActCls: checkoutAct,
    });
  };

  sateValChange = (field, value) => {
    if (field === "catering_date") {
      this.setState({ catering_date: value });
    } else if (field === "catering_time") {
      this.setState({ catering_time: value });
    } else if (field === "breaktime_enable") {
      this.setState({ breaktime_enable: value });
    } else if (field === "breaktime_count") {
      this.setState({ breaktime_count: value });
    } else if (field === "breaktime_list") {
      this.setState({ breaktime_list: value });
    } else if (field === "break_timeHtml") {
      this.setState({ break_timeHtml: value });
    } else if (field === "outlet_id") {
      this.setState({ catering_outlet_id: value });
    } else if (field === "hall_id") {
      this.setState({ catering_hall_id: value });
    } else if (field === "venue_submit") {
      this.setState({ trigger_venue_submit: value });
    } else if (field === "catePrimaryId") {
      this.setState({ catePrimaryId: value });
    } else if (field === "cateVenueFlag") {
      this.setState({ cateVenueFlag: value });
    } else if (field === "venueTypeChanged") {
      this.setState({ trigger_venue_type: value });
    } else if (field === "category_id") {
      this.setState({ category_id: value });
    } else if (field === "subcategory_id") {
      this.setState({ subcategory_id: value });
    } else if (field === "catering_product_id") {
      this.setState({ catering_product_id: value }, () => {
        const parameter = "&product_id=" + value + "&product_is_combo='Yes'";
        this.props.getProductDetail(parameter);
      });
    } else if (field === "checkout_submit") {
      this.setState({ trigger_checkout_submit: value });
    } else if (field === "category_name") {
      this.setState({ category_name: value });
    } else if (field === "active_tabflag") {
      this.setState({ active_tabflag: value });
    } else if (field === "add_new_item") {
      this.setState({ add_new_item: value });
      /*this.props.getCateringCartDetail();*/
    }
  };

  changeCatgryDetFun = (valArr) => {
    cookie.save("catering_categoryPryId", valArr["categoryPryId"]);
    cookie.save("catering_categoryId", valArr["category_id"]);
    cookie.save("catering_categoryName", valArr["category_name"]);

    this.setState({
      catePrimaryId: valArr["categoryPryId"],
      category_id: valArr["category_id"],
      category_name: valArr["category_name"],
    });
  };

  showsActiveDiv = (valtxt) => {
    var tabflag = this.state.active_tabflag;
    return parseInt(valtxt) === parseInt(tabflag) ? "block" : "none";
  };

  showsActiveHdr = (valtxt) => {
    var tabflag = this.state.active_tabflag;
    return parseInt(valtxt) === parseInt(tabflag) ? "active" : "";
  };

  componentDidMount() {
    var venueTypeInitail =
      typeof cookie.load("venueTypeInitail") !== "undefined"
        ? cookie.load("venueTypeInitail")
        : "";
    if (venueTypeInitail === "") {
      this.eventTypePopupMain();
    } else {
      if (
        this.state.venue_type_Initail === "outdoor" &&
        (cookie.load("cateringOutletId") === undefined ||
          cookie.load("cateringOutletId") === "")
      ) {
        var popupIdtxt = "#catering-postcode-popup";
        $.magnificPopup.open({
          items: {
            src: popupIdtxt,
          },
          type: "inline",
        });
      }
    }
  }

  closeEvtType() {
    $.magnificPopup.close();
    if (this.state.venue_type_Initail === "") {
      cookie.save("orderPopuptrigger", "Yes");
      this.props.history.push("/");
      return false;
    }
  }

  /* find Zone*/
  findOutletBasedZone(first, availability) {
    if (first) {
      var postalcode = $("#catering_postalcode").val();
    } else {
      var postalcode = $("#catering_postalcode1").val();
    }

    var outletIdTxt = this.state.delivery_outlet_id;

    if (postalcode.length < 5) {
      $(".postal_error").html(
        '<span class="error">Please enter valid postal code.</span>'
      );
      return false;
    }

    showLoader("delivery_submit_cls", "class");
    axios
      .all([
        axios.get(
          apiUrlV2 +
            "outlets/findOutletZone?app_id=" +
            appId +
            "&availability_id=" +
            availability +
            "&postal_code=" +
            postalcode +
            "&postalcode_basedoutlet=yes"
        ),
      ])
      .then(
        axios.spread((res, timeslt) => {
          /* Success response */
          if (res.data.status === "ok") {
            cookie.save("cateringOutletId", res.data.result_set.outlet_id);
            cookie.save("cateringOutletName", res.data.result_set.outlet_name);

            var orderDeliveryAddress =
              res.data.result_set.postal_code_information.zip_buno +
              " " +
              res.data.result_set.postal_code_information.zip_sname;

            cookie.save("catering_postal_code", postalcode);
            cookie.save("catering_address_line", orderDeliveryAddress);
            cookie.save(
              "zone_delivery_charge",
              res.data.result_set.zone_delivery_charge
            );
            cookie.save(
              "zone_additional_delivery_charge",
              res.data.result_set.zone_additional_delivery_charge
            );
            cookie.save("zoneDetailData", res.data.result_set);

            this.setState(
              {
                catering_outlet_id: res.data.result_set.outlet_id,
                orderDeliveryAddress: orderDeliveryAddress,
              },
              () => {
                $.magnificPopup.open({
                  items: {
                    src: "#catering-awesome-popup",
                  },
                  type: "inline",
                });
              }
            );
          }

          /* Error response */
          if (res.data.status === "error") {
            hideLoader("delivery_submit_cls", "class");
            $.magnificPopup.close();
            // this.setState({ deliveryInfo: deliveryInfo });

            $.magnificPopup.close();
            $.magnificPopup.open({
              items: {
                src: "#error-catering-postal-popup",
              },
              type: "inline",
            });

            if (first === 0) {
              var mgsTxt =
                res.data.message !== ""
                  ? res.data.message
                  : "Please enter valid postal code.";
              $(".postal_error").html(
                '<span class="error">' + mgsTxt + "</span>"
              );
            }
          }
        })
      );
  }

  selectOutlet(outletInfo) {
    cookie.save("cateringOutletId", outletInfo.outlet_id);
    cookie.save("cateringOutletName", outletInfo.outlet_name);
    cookie.save("catering_postal_code", outletInfo.outlet_postal_code);
    cookie.save("catering_address_line", outletInfo.outlet_address_line1);

    this.setState(
      {
        catering_outlet_id: outletInfo.outlet_id,
        orderDeliveryAddress: outletInfo.outlet_address_line1,
      },
      () => {
        $.magnificPopup.close();
      }
    );
  }

  loadcartitems = (loadvalue) => {
    if (loadvalue) {
      this.loadCartDet();
      $(".order_card").first().removeClass("light").addClass("dark");
    }
  };
  loadCartDet() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }

    axios
      .get(apiUrlCtrng + "cart/contents?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          if (typeof res.data.result_set === "undefined") {
          } else {
            var cartDetailsMain = res.data.result_set.cart_details;
            var cartItems = res.data.result_set.cart_items;
            var cartcount = res.data.result_set.cart_details.cart_total_items;
            cookie.save(
              "orderOutletId",
              res.data.result_set.cart_details.outlet_id
            );
            cookie.save(
              "order_date",
              moment(
                res.data.result_set.cart_details.cart_catering_order_date
              ).format("YYYY/MM/DD")
            );

            this.setState({
              orderDate: new Date(
                res.data.result_set.cart_details.cart_catering_order_date
              ),
            });
          }
        } else {
          this.setState({ cart_display: "none" });
          //this.props.history.push('/package');
        }

        this.setState(
          {
            cartDetailsMain: cartDetailsMain,
            cartItems: cartItems,
            cartcount: cartcount,
          },
          function () {
            this.makeBrkTimeList();
            this.getSurCharge();
          }.bind(this)
        );
      });
  }

  getCartDetList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    cookie.save("cartDetail", cartDetailsSate);
    var cartBrktimeLst = this.state.cart_brktime_list;
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 &&
      cartBrktimeLst !== undefined &&
      cartBrktimeLst !== null
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      if (breaktimeEnable === "Yes" && cartBrktimeLst.length > 0) {
        return this.cartBrkTimeList();
      } else {
        return this.cartListOnly();
      }
    }
  }

  makeBrkTimeList() {
    var cartDetailsSate = this.state.cartDetailsMain;
    var cartItems = this.state.cartItems;
    var cartBrkTmList = [];
    if (
      cartDetailsSate !== undefined &&
      cartDetailsSate !== null &&
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.cart_breaktime_enable;
      var breaktimeCount = cartDetailsSate.cart_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = [];
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].cart_item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = [];
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].cart_item_breaktime_indexflag,
              break_time_start: cartItems[key].cart_item_breaktime_started,
              break_time_end: cartItems[key].cart_item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }
    this.setState({ cart_brktime_list: cartBrkTmList });
  }

  getSurCharge() {
    if (typeof cookie.load("UserId") === "undefined") {
      var customerParam = "&reference_id=" + getReferenceID();
    } else {
      var customerParam = "&customer_id=" + cookie.load("UserId");
    }
    axios
      .get(apiUrlCtrng + "orders/getSurCharge?app_id=" + appId + customerParam)
      .then((res) => {
        if (res.data.status === "ok") {
          var surcharge = 0;
          if (
            typeof res.data.total_charge !== "undefined" &&
            res.data.total_charge > 0
          ) {
            surcharge = res.data.total_charge;
          }
          this.setState({ surcharge: surcharge, surchargeLog: res.data.logs });
        }
      });
  }

  cartBrkTimeList() {
    ReactTooltip.rebuild();
    var cartBrktimeLst = this.state.cart_brktime_list;

    var index = 0;
    let existSelectedTimes = [];
    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => {
      if (indx === 0) {
        var classes = "dark";
      } else {
        var classes = "light";
      }
      var order_no = parseInt(indx) + 1;
      existSelectedTimes.push(
        cartBrk["cart_detaile"].break_time_start +
          "-" +
          cartBrk["cart_detaile"].break_time_end
      );
      var currentSelection =
        cartBrk["cart_detaile"].break_time_start +
        "-" +
        cartBrk["cart_detaile"].break_time_end;
      const imageName = this.getImageNameAlt();
      var CartItemList = "";
      cartBrk["cart_items"].map(function (item) {
        CartItemList = CartItemList + item.cart_item_id + ",";
      });

      return (
        <div
          className={"order_card " + classes}
          key={indx}
          data-index=""
          id={"tileSlot_" + indx}
        >
          <div className="order_card_head">
            Order Details {"-"} {order_no}
            <Link
              onClick={this.deleteCartItm.bind(
                this,
                cartBrk["cart_items"][0]["cart_item_id"],
                "alert"
              )}
              to="#"
            >
              <img src={imageName} alt="delete" />
            </Link>
          </div>
          <div className="order_card_body">
            <div className="event_timing">
              <div className="form-group">
                <div className="input_field">
                  <label>Event Start Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control start_time"
                      id="event_start_timer"
                      name="start_time"
                      data-itemid={CartItemList}
                      data-rootid={"tileSlot_" + indx}
                      onChange={this.handleUpdateChange.bind(this)}
                    >
                      {Parser(
                        this.getbreaktimelist(
                          cartBrk["cart_detaile"].break_time_start,
                          currentSelection
                        )
                      )}
                    </select>
                    <span
                      className="error start_time_error"
                      style={{ display: "none" }}
                    >
                      This Field is required!
                    </span>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warning} alt="warning" />
                </span>
              </div>
              <div className="form-group">
                <div className="input_field">
                  <label>Event End Time</label>
                  <div className="custom_select">
                    <select
                      className="form-control end_time"
                      value={this.props.reason}
                      id="event_end_timer"
                      name="end_time"
                      data-itemid={CartItemList}
                      data-rootid={"tileSlot_" + indx}
                      onChange={this.handleUpdateChange.bind(this)}
                    >
                      {Parser(
                        this.getbreaktimelist(
                          cartBrk["cart_detaile"].break_time_end,
                          currentSelection
                        )
                      )}
                    </select>
                    <span
                      className="error end_time_error"
                      style={{ display: "none" }}
                    >
                      This Field is required!
                    </span>
                  </div>
                </div>
                <span
                  data-tip={this.state.delivery_blocks}
                  data-html={true}
                  data-class="default_tooltip order_tooltip"
                  className="tooltip_link"
                >
                  <img src={warning} alt="warning" />
                </span>
              </div>
            </div>

            <div className="products_info">
              {this.cartItemlist(cartBrk["cart_items"], index)}
            </div>
          </div>
        </div>
      );
      index++;
    });
    // this.setState({existSelectedTimes:existSelectedTimes})
    return cartBrktmhtml;
  }

  cartListOnly() {
    var cartItems = this.state.cartItems;
    return (
      <div className="order_card_body">{this.cartItemlist(cartItems)}</div>
    );
  }

  changeImage() {
    this.setState((state) => ({ open: !state.open }));
  }
  getImageName = () => (this.state.open ? delete_dark : delete_white);
  getImageNameAlt = () => (this.state.open ? delete_white : delete_dark);

  deleteCartItm(cart_item_id, checkalert) {
    if (checkalert === "alert") {
      swal({
        title: "Are you sure?",
        text: "Are you sure that you want to delete this item?",
        icon: "warning",
        dangerMode: true,
      }).then((willDelete) => {
        if (willDelete) {
          if (cart_item_id) {
            // this.props.deleteCartDetail(cart_item_id);
            var postCrtObject = {
              app_id: appId,
              cart_item_id: cart_item_id,
              cartAction: "Delete",
            };
            if (typeof cookie.load("UserId") === "undefined") {
              postCrtObject["reference_id"] = getReferenceID();
            } else {
              postCrtObject["customer_id"] = cookie.load("UserId");
            }

            axios
              .post(apiUrlCtrng + "cart/delete", qs.stringify(postCrtObject))
              .then((res) => {
                if (res.data.status === "ok") {
                  // if($('.order_card').length <= 1) { this.changeImage() };

                  this.loadCartDet();
                  swal(
                    "Success!",
                    "Nice! Products updated to your cart",
                    "success"
                  );
                } else {
                  swal(
                    "Error!",
                    "Sorry! Products can`t update your cart",
                    "error"
                  );
                }
              })
              .catch(function (error) {
                console.log(error);
                swal("Error!", "something went wrong", "error");
              });
          } else {
            swal("Error!", "You haven’t added any items yet !", "error");
          }
        }
      });
    } else {
      if (cart_item_id) {
        // this.props.deleteCartDetail(cart_item_id);
        var postCrtObject = {
          app_id: appId,
          cart_item_id: cart_item_id,
          cartAction: "Delete",
        };
        if (typeof cookie.load("UserId") === "undefined") {
          postCrtObject["reference_id"] = getReferenceID();
        } else {
          postCrtObject["customer_id"] = cookie.load("UserId");
        }

        axios
          .post(apiUrlCtrng + "cart/delete", qs.stringify(postCrtObject))
          .then((res) => {
            if (res.data.status === "ok") {
              this.loadCartDet();
              swal(
                "Success!",
                "Nice! Products updated to your cart",
                "success"
              );
            } else {
              swal("Error!", "Sorry! Products can`t update your cart", "error");
            }
          })
          .catch(function (error) {
            swal("Error!", "something went wrong", "error");
          });
      } else {
        swal("Error!", "You haven’t added any items yet !", "error");
      }
    }
  }

  handleUpdateChange(e) {
    /* var start_validate = this.state.start_validate;
		var end_validate = this.state.end_validate; */

    if (e.target.name === "start_time") {
      if (e.target.value === "") {
        this.setState(
          { start_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").show();
      } else {
        this.setState(
          { start_validate: true, event_start: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".start_time_error").hide();
      }
    }

    if (e.target.name === "end_time") {
      if (e.target.value === "") {
        this.setState(
          { end_validate: false },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").show();
      } else {
        this.setState(
          { end_validate: true, event_end: e.target.value },
          function () {
            this.showSuccess();
          }.bind(this)
        );
        $(".end_time_error").hide();
      }
    }
    var itemid = e.target.attributes.getNamedItem("data-itemid");
    if (itemid !== "" && itemid !== undefined && itemid !== null) {
      itemid = e.target.attributes.getNamedItem("data-itemid").value;
      var rootid = e.target.attributes.getNamedItem("data-rootid").value;
      var CartItemList = itemid.split(",");
      var start_time = $("#" + rootid + " .start_time").val();
      var end_time = $("#" + rootid + " .end_time").val();
      if (start_time < end_time) {
        var currents = this;
        CartItemList.map(function (item) {
          if (item !== "") {
            currents.updateBreakTime(item, start_time, end_time);
          }
        });
      }
    }

    // itemid = itemid.replace('data-itemid"', "");
  }

  getbreaktimelist(selcted, currentSelection) {
    var breakTimeHtml = "";
    let existSelectedTimes = [];
    if (
      this.state.cart_brktime_list !== "" &&
      typeof this.state.cart_brktime_list !== "undefined"
    ) {
      this.state.cart_brktime_list.map(function (cart, index) {
        existSelectedTimes.push(
          cart.cart_detaile.break_time_start +
            "-" +
            cart.cart_detaile.break_time_end
        );
      });
    }

    if (this.state.timeslot_arr) {
      breakTimeHtml += "<option value=''>Select</option>";

      this.state.timeslot_arr.map((option, index) => {
        if (option.isclose === "No") {
          //   breakTimeHtml +=
          //     "<option value='" +
          //     option.timetext +
          //     "' disabled>" +
          //     option.timetext +
          //     "</option>";
          // } else {
          if (selcted !== "" && selcted === option.timetext) {
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' selected='selected'>" +
              option.timetext +
              "</option>";
          } else {
            let diableTime = this.timevalidataion(
              option.timetext,
              existSelectedTimes,
              currentSelection
            );
            diableTime = diableTime !== undefined ? diableTime : "";
            breakTimeHtml +=
              "<option value='" +
              option.timetext +
              "' " +
              diableTime +
              ">" +
              option.timetext +
              "</option>";
          }
        }
      });
    }
    return breakTimeHtml;
  }

  updateBreakTime(cartItmId, startTime, endTime) {
    var postCrtObject = {};
    postCrtObject = {
      app_id: appId,
      cart_item_id: cartItmId,
      break_time: startTime,
      break_time_end: endTime,
    };
    if (typeof cookie.load("UserId") === "undefined") {
      postCrtObject["reference_id"] = getReferenceID();
    } else {
      postCrtObject["customer_id"] = cookie.load("UserId");
    }

    axios
      .post(apiUrlCtrng + "cart/updateBreakTime", qs.stringify(postCrtObject))
      .then((res) => {
        if (res.data.status === "ok") {
          this.loadCartDet();
          // swal("Success!", "Nice! Products updated to your cart", "success");
        } else {
          //swal("Error!", "Sorry! Products can`t update your cart", "error");
        }
      })
      .catch(function (error) {
        swal("Error!", "something went wrong", "error");
      });
  }

  timevalidataion(timetext, existSelectedTimes, currentSelection) {
    var TodayDate = new Date();
    var currentTime = timetext.split(":");
    TodayDate.setHours(currentTime[0]);
    TodayDate.setMinutes(currentTime[1]);
    if (currentSelection !== "" && typeof currentSelection !== "undefined") {
      const index = existSelectedTimes.indexOf(currentSelection);
      if (index > -1) {
        existSelectedTimes.splice(index, 1);
      }
    }
    var disabled = "";
    existSelectedTimes.map(function (cartTime) {
      var cartTimesplit = cartTime.split("-");
      var fromTime = cartTimesplit[0].split(":");
      var toTime = cartTimesplit[1].split(":");

      var ExistDateFrom = new Date();
      ExistDateFrom.setHours(fromTime[0]);
      ExistDateFrom.setMinutes(fromTime[1]);

      var ExistDateTo = new Date();
      ExistDateTo.setHours(toTime[0]);
      ExistDateTo.setMinutes(toTime[1]);
      if (currentSelection !== "" && typeof currentSelection !== "undefined") {
      }
      if (TodayDate >= ExistDateFrom && TodayDate <= ExistDateTo) {
        disabled = 1;
      }
    });
    if (disabled === 1) {
      return "disabled";
    }
  }

  render() {
    var settingsCartering = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            infinite: false,
          },
        },
        {
          breakpoint: 520,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    return (
      <div>
        <div className="common-top-div catering-main-div">
          {/* Header start */}
          <Header />
          {/* Header End */}

          {/* innersection_wrap - start */}
          <div className="container">
            <div className="innersection_wrap">
              <div className="mainacc_toptext">
                <h2>Catering</h2>
                {/* <p>searching for a venue to host a party or company event? look no further.
Located conveniently in the heart of city, our outlet at Galaxis is open for event bookings. Select from our Catering Menus to satisfy your hungry guests.</p> */}
              </div>

              {/* tab sec top div - start */}
              <div className="tab_sec order_nav">
                <ul
                  className="mobile-catering-slider catering-top-navdiv"
                  style={{ display: "none" }}
                >
                  <Slider {...settingsCartering}>
                    <li
                      className={
                        this.state.commonTabCls +
                        " categorylistLi " +
                        this.state.categoryActCls
                      }
                      onClick={this.setActiveTab.bind(this, 1)}
                    >
                      <a href="javascript:void(0)">
                        <span className="nos">1</span>
                        <span>MENU SELECTION</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.commonTabCls +
                        " venueDetLi " +
                        this.state.venueDetActCls
                      }
                      onClick={this.setActiveTab.bind(this, 2)}
                    >
                      <a href="javascript:void(0)">
                        <span className="nos">2</span>
                        <span>DATE & TIME</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.commonTabCls +
                        " packageLi " +
                        this.state.packageActCls
                      }
                      onClick={this.setActiveTab.bind(this, 3)}
                    >
                      <a href="javascript:void(0)">
                        <span className="nos">3</span>
                        <span>YOUR PACKAGE</span>
                      </a>
                    </li>
                    <li
                      className={
                        this.state.commonTabCls +
                        " checkoutLi " +
                        this.state.checkoutActCls
                      }
                      onClick={this.setActiveTab.bind(this, 4)}
                    >
                      <a href="javascript:void(0)">
                        <span className="nos">4</span>
                        <span>CHECKOUT</span>
                      </a>
                    </li>
                  </Slider>
                </ul>

                <ul className="nav nav-tabs catering-top-navdiv">
                  <li
                    className={
                      this.state.commonTabCls +
                      " categorylistLi " +
                      this.state.categoryActCls
                    }
                    onClick={this.setActiveTab.bind(this, 1)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">1</span>
                      <span className="nos-name">menu selection</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " venueDetLi " +
                      this.state.venueDetActCls
                    }
                    onClick={this.setActiveTab.bind(this, 2)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">2</span>
                      <span className="nos-name">date & time</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " packageLi " +
                      this.state.packageActCls
                    }
                    onClick={this.setActiveTab.bind(this, 3)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">3</span>
                      <span className="nos-name">your package</span>
                    </a>
                  </li>
                  <li
                    className={
                      this.state.commonTabCls +
                      " checkoutLi " +
                      this.state.checkoutActCls
                    }
                    onClick={this.setActiveTab.bind(this, 4)}
                  >
                    <a href="javascript:void(0)">
                      <span className="nos">4</span>
                      <span className="nos-name">checkout</span>
                    </a>
                  </li>
                </ul>

                {/* tab content main div - start */}
                <div className="tab-content">
                  {/* Tab1 Product list */}
                  <div
                    className="tab-pane-main categorylistTopDiv"
                    style={{ display: this.showsActiveDiv(1) }}
                  >
                    <Productlist
                      categoryState={this.state}
                      setActiveTab={this.setActiveTab}
                      sateValChangefun={this.sateValChange}
                      showsActiveDiv={this.showsActiveDiv}
                      showsActiveHdr={this.showsActiveHdr}
                    />
                  </div>

                  {/* Tab2 Date & Time */}
                  <div
                    className="tab-pane-main venueTopDiv"
                    style={{ display: this.showsActiveDiv(2) }}
                  >
                    <div class="products_list_heading">
                      <h3>select date & time</h3>
                    </div>
                    {/* <Venuedetails categoryState={this.state} sateValChangefun={this.sateValChange} setActiveTab={this.setActiveTab} showsActiveDiv={this.showsActiveDiv} showsActiveHdr={this.showsActiveHdr} changeCatgryDet={this.changeCatgryDetFun}/> */}
                    <Eventdatetime
                      categoryState={this.state}
                      sateValChangefun={this.sateValChange}
                      setActiveTab={this.setActiveTab}
                      showsActiveDiv={this.showsActiveDiv}
                      showsActiveHdr={this.showsActiveHdr}
                      changeCatgryDet={this.changeCatgryDetFun}
                    />
                  </div>

                  {/* Tab3 Package Details */}
                  <div
                    className="tab-pane-main packageTopDiv upkg_tabsec"
                    style={{ display: this.showsActiveDiv(3) }}
                  >
                    <Productdetails
                      categoryState={this.state}
                      loadcartitems={this.loadcartitems.bind(this)}
                      sateValChangefun={this.sateValChange}
                      setActiveTab={this.setActiveTab}
                      showsActiveDiv={this.showsActiveDiv}
                      showsActiveHdr={this.showsActiveHdr}
                      changeCatgryDet={this.changeCatgryDetFun}
                    />
                  </div>

                  {/* Tab4 Checkout Details */}
                  <div
                    className="tab-pane-main checkoutTopDiv"
                    style={{ display: this.showsActiveDiv(4) }}
                  >
                    <Ordersummary
                      categoryState={this.state}
                      loadcartitems={this.loadcartitems.bind(this)}
                      sateValChangefun={this.sateValChange}
                      setActiveTab={this.setActiveTab}
                      showsActiveDiv={this.showsActiveDiv}
                      showsActiveHdr={this.showsActiveHdr}
                      changeCatgryDet={this.changeCatgryDetFun}
                    />
                    {/* <Checkout categoryState={this.state} sateValChangefun={this.sateValChange} setActiveTab={this.setActiveTab} showsActiveDiv={this.showsActiveDiv} showsActiveHdr={this.showsActiveHdr}/> */}
                  </div>
                </div>
                {/* tab content main div - End */}
              </div>
              {/* tab sec top div - end */}
            </div>
          </div>
          {/* innersection_wrap - end */}
        </div>
        {/* Footer section */}

        {/* venue type popup - start */}
        <div
          id="venue-popup"
          className="white-popup mfp-hide popup_sec venue_popup"
        >
          <div className="full-login-new-header">
            <h3>Select event type</h3>
          </div>

          <div className="full-login-new-body">
            <ul className="venue_type_item">
              <li
                className={
                  this.state.venue_type_Initail === "indoor"
                    ? "venuetype-active"
                    : ""
                }
              >
                <a
                  href="javascript:;"
                  onClick={this.changeEvtType.bind(this, "indoor")}
                >
                  In-house Catering
                </a>
              </li>

              <li
                className={
                  this.state.venue_type_Initail === "outdoor"
                    ? "venuetype-active"
                    : ""
                }
              >
                <a
                  href="javascript:;"
                  onClick={this.changeEvtType.bind(this, "outdoor")}
                >
                  Delivery
                </a>
              </li>
            </ul>
          </div>

          <button
            title="Close (Esc)"
            onClick={this.closeEvtType.bind(this)}
            type="button"
            className="mfp-custom-close close-icon-but"
          >
            ×
          </button>
        </div>
        {/* venue type popup - end */}

        {/*  Catering Postal code Popup - start */}
        <div
          id="catering-postcode-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="full-login-new-header">
            <h3> Delivery </h3>
            <p>Let us know Your Delivery Location</p>
          </div>

          <div className="full-login-new-body">
            <img
              className="pop-scooter-img"
              src={fbscootersvg}
              alt="Delivery"
            />

            <div className="innervmid_in">
              {/* {cookie.load("UserId") && (
							<div className="address-list-cls address-list-main">
							{this.userAddressList(1)}
							</div>
						)} */}

              <div className="form-group">
                <div className="datetime_selt_lbl">Enter your postal code</div>
                <div
                  className={
                    // this.state.secondaryaddresslist.length > 0
                    // ? "focus-out focused":
                    "focus-out"
                  }
                >
                  <label>Postal Code</label>
                  <input
                    type="text"
                    id="catering_postalcode"
                    pattern="\d*"
                    maxLength="6"
                    className="form-control input-focus"
                  />
                  <div className="postal_error"></div>
                </div>
              </div>
              <div className="btn_sec">
                <div className="two-button-row">
                  <div className="go_second">
                    {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                    <a
                      href="javascript:;"
                      onClick={this.eventTypePopupMain.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  <div className="con_first delivery_submit_cls">
                    <input
                      type="button"
                      onClick={this.findOutletBasedZone.bind(
                        this,
                        1,
                        cateringId
                      )}
                      className="button button-right delivery_submit"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* catering Popup - end */}

        {/* Delevery Popup error - start */}

        <div
          id="error-catering-postal-popup"
          className="white-popup mfp-hide popup_sec error_postal_popup"
        >
          <div className="full-login-new-header">
            <h3 className="text-uppercase">Sorry </h3>
            <p>We cant find your postal code</p>
          </div>

          <div className="full-login-new-body">
            <img
              className="pop-scooter-img"
              src={iconUnhappy}
              alt="Not allow Delivery"
            />

            <div className="innervmid_in">
              <form className="form_sec">
                <div className="form-group">
                  <div className="datetime_selt_lbl">
                    Enter your postal code
                  </div>
                  <div
                    className={
                      // this.state.secondaryaddresslist.length > 0
                      // 	? "focus-out focused" :
                      "focus-out"
                    }
                  >
                    <label>Postal code</label>
                    <input
                      type="text"
                      id="catering_postalcode1"
                      pattern="\d*"
                      maxLength="6"
                      className="form-control input-focus"
                    />
                    <div className="postal_error"></div>
                  </div>
                </div>
                <div className="btn_sec delivery_submit_cls delivery_submit_div">
                  <input
                    type="button"
                    onClick={this.findOutletBasedZone.bind(this, 0, cateringId)}
                    className="button delivery_submit"
                    value="Continue"
                  />
                </div>
              </form>
            </div>
          </div>
        </div>

        {/* Delevery Popup error - Start */}

        {/* success popup - Start */}
        <div
          id="catering-awesome-popup"
          className="white-popup mfp-hide awesome_popup"
        >
          <div className="full-login-new-header">
            <h3> Delivery address</h3>
          </div>

          <div className="full-login-new-body">
            <img className="pop-scooter-img" src={iconWin} alt="Delivery" />
            <h2 className="text-uppercase">Awesome</h2>

            <div className="awesome_del">
              <h5>We can Deliver to your location!</h5>
              <input
                type="text"
                readonly=""
                className="form-control input-focus"
                value={cookie.load("catering_postal_code")}
              />
              <h2>{this.state.orderDeliveryAddress}</h2>
            </div>
            <div className="btn_sec">
              <a href="/" className="button" title="Go Back">
                Go Back
              </a>
              <input
                type="button"
                onClick={this.closeEvtType.bind(this)}
                className="button"
                value="Continue"
              />
            </div>
          </div>
        </div>
        {/* success popup - end */}

        {/*  Catering Postal code Popup - start */}
        <div
          id="catering-inhouse-popup"
          className="white-popup mfp-hide popup_sec delevery_popup"
        >
          <div className="full-login-new-header">
            <h3> In-house Catering </h3>
          </div>

          <div className="full-login-new-body">
            <div className="innervmid_in">
              <div className="address-list-cls address-list-main">
                <h3>Outlet Address</h3>
                <p>{this.state.cateringOutlet.outlet_address_line1}</p>
              </div>
              <div className="btn_sec">
                <div className="two-button-row">
                  <div className="go_second">
                    {/*<a href="javascript:;" onClick={this.closepopup.bind(this)} className="button button-left" title="Go Back">Go Back</a>*/}
                    <a
                      href="javascript:;"
                      onClick={this.eventTypePopupMain.bind(this)}
                      className="button button-left"
                      title="Go Back"
                    >
                      Go Back
                    </a>
                  </div>
                  <div className="con_first delivery_submit_cls">
                    <input
                      type="button"
                      onClick={this.selectOutlet.bind(
                        this,
                        this.state.cateringOutlet
                      )}
                      className="button button-right delivery_submit"
                      value="Continue"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* catering Popup - end */}

        <div
          className="white-popup mfp-hide popup_sec"
          id="clearpopupid"
          style={{ maxWidth: 500 }}
        >
          <div className="custom_alert">
            <div className="custom_alertin">
              <div className="alert_height">
                <div className="alert_header" style={{ textAlign: "center" }}>
                  Wait a second !
                </div>
                <div className="alert_body">
                  <img src={CantDlyImg} alt="Cart" />
                  <p>By switching you are about to clear your cart.</p>
                  <p>Do you wish to proceed ?</p>
                  <div className="alt_btns">
                    <a
                      href="javascript:void(0);"
                      className="button popup-modal-dismiss"
                    >
                      Cancel
                    </a>
                    <a
                      href="javascript:void(0);"
                      className="button btn_yellow"
                      onClick={this.cartDestoryAndRstEvntFun.bind(this)}
                    >
                      Yes Proceed
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    );
  }
}

const mapStateTopProps = (state) => {
  var tempArr = Array();
  var menu_slug = "";
  var menu_type = "";
  var navigateRst = Array();
  var navigateCmn = Array();
  if (Object.keys(state.product).length > 0) {
    var tempArr = !("menuNavigation" in state.product[0])
      ? Array()
      : state.product[0].menuNavigation;
    if (Object.keys(tempArr).length > 0) {
      if (tempArr[0].status === "ok") {
        navigateRst = tempArr[0].result_set;
        navigateCmn = tempArr[0].common;
        menu_slug = tempArr[0].result_set[0].pro_cate_slug;
        menu_type =
          tempArr[0].result_set[0].menu_type == "main"
            ? "category"
            : "subcategory";
      }
    }
  }

  var overAllCateringCart = Array();
  var cateringCartDetails = Array();
  var cateringCartItems = Array();
  var cateringCartTotalItmCount = 0;
  if (Object.keys(state.cateringcartlistdetail).length > 0) {
    var resultSetArr = !("result_set" in state.cateringcartlistdetail[0])
      ? Array()
      : state.cateringcartlistdetail[0].result_set;
    if (
      state.cateringcartlistdetail[0].status === "ok" &&
      Object.keys(resultSetArr).length > 0
    ) {
      overAllCateringCart = resultSetArr;
      cateringCartDetails = resultSetArr.cart_details;
      cateringCartItems = resultSetArr.cart_items;
      cateringCartTotalItmCount = resultSetArr.cart_details.cart_total_items;
    }
  }

  var productData = Array();
  //   if (Object.keys(state.cateringprodcutlist).length > 0) {
  //     if ( state.cateringprodcutlist[0].status === "ok" && Object.keys(state.cateringprodcutlist[0].result_set).length > 0 ) {
  //       productData = state.cateringprodcutlist[0];
  //     }
  //   }

  if (Object.keys(state.cateringproductdetail).length > 0) {
    if (
      state.cateringproductdetail[0].status === "ok" &&
      Object.keys(state.cateringproductdetail[0].result_set).length > 0
    ) {
      productData = state.cateringproductdetail[0];
    }
  }
  return {
    navigateMenu: navigateRst,
    navigateCommon: navigateCmn,
    selectedNavigation: menu_slug,
    selectedSlugType: menu_type,
    overAllCateringCart: overAllCateringCart,
    cateringCartDetails: cateringCartDetails,
    cateringCartItems: cateringCartItems,
    cateringCartTotalItmCount: cateringCartTotalItmCount,
    productData: productData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    // getMenuNavigationList: () => {
    //   dispatch({ type: GET_MENU_NAVIGATION });
    // },
    getCateringCartDetail: () => {
      dispatch({ type: GET_CATERINGCART_DETAIL });
    },
    getProduct: (parameter) => {
      dispatch({ type: GET_CATERING_PRODCUTLIST, parameter });
    },
    getProductDetail: (proSlug) => {
      dispatch({ type: GET_CATERING_PRODCUTDETAIL, proSlug });
    },
  };
};
export default connect(mapStateTopProps, mapDispatchToProps)(Catering);
