/* Live */
const hostname = window.location.hostname;
export const deliveryId = "634E6FA8-8DAF-4046-A494-FFC1FCF8BD11";
export const pickupId = "718B1A92-5EBB-4F25-B24D-3067606F67F0";
export const cateringId = "EB62AF63-0410-47CC-9464-038E796E28C4";
export const reservationId = "79FA4C7F-75A1-4A95-B7CE-81ECA2575363";
export const dineinId = "EF9FB350-4FD4-4894-9381-3E859AB74019";

export const apiUrl = "https://ccpl.ninjaos.com/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/Pushorder/";
export const timThumpUrl = "https://ccpl.ninjaos.com/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/media/dev_team/";
export const blogImageUrl = "https://ccpl.ninjaos.com/media/dev_team/blog/";
export const tagImageUrl = "https://ccpl.ninjaos.com/media/dev_team/tag/";
export const proFolderUrl = "https://ccpl.ninjaos.com/media/dev_team/products/";
export const outletUrl = "https://ccpl.ninjaos.com/media/dev_team/outlet/";

/* export const apiUrl = "https://ccpl.ninjaos.com/development/api/";
export const apiUrlV2 = "https://ccpl.ninjaos.com/development/apiv2/";
export const apiUrlPro = "https://ccpl.ninjaos.com/development/ninjapro/";
export const apiUrlCtrng = "https://ccpl.ninjaos.com/development/cateringv2/";
export const apiUrlNotify = "https://ccpl.ninjaos.com/development/Pushorder/";
export const timThumpUrl =
  "https://ccpl.ninjaos.com/development/timthumb.php?src=";
export const mediaUrl = "https://ccpl.ninjaos.com/development/media/dev_team/";
export const blogImageUrl =
  "https://ccpl.ninjaos.com/development/media/dev_team/blog/";
export const tagImageUrl =
  "https://ccpl.ninjaos.com/development/media/dev_team/tag/";
export const proFolderUrl =
  "https://ccpl.ninjaos.com/development/media/dev_team/products/";
export const outletUrl =
  "https://ccpl.ninjaos.com/development/media/dev_team/outlet/"; */

var domainValues = {
  localhost: {
    appId: "99F42644-8372-42EA-8BF0-215DF11369E1",
    baseUrl: "http://localhost:3000/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Ninja Enterprise",
    fbAppId: "284656430443684",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 321,
    currencySybmol: "$",
    headermenu: "header-menu-fllvp9k2qcwtbmmnacy51qxsz",
    foldername: "foodbarn",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
  },
  "192.168.218.66": {
    appId: "99F42644-8372-42EA-8BF0-215DF11369E1",
    baseUrl: "http://192.168.218.66:3000/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Ninja Enterprise",
    fbAppId: "284656430443684",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    defaultoutlet: 321,
    currencySybmol: "$",
    headermenu: "header-menu-sblcpuiewrzotjn1nvqzk53ud",
    foldername: "foodbarn",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
  },
  "ninjaent.ninjaos.com": {
    appId: "99F42644-8372-42EA-8BF0-215DF11369E1",
    baseUrl: "https://ninjaent.ninjaos.com/",
    CountryTxt: "Singapore",
    productNoImg: "/img/product-noimg.jpg",
    companyname: "Ninja Enterprise",
    fbAppId: "284656430443684",
    stripeEmail: "dev@jankosoft.com",
    stripeReference: "ninjaenterprise",
    stripeDesc: "My Checkout",
    stripeCurrency: "SGD",
    currencySybmol: "$",
    defaultoutlet: 321,
    headermenu: "header-menu-fllvp9k2qcwtbmmnacy51qxsz",
    foldername: "foodbarn",
    instaToken:
      "IGQVJXVVBvS205QXppOFFpSzlrd3dWRXc4Sk9JcVF5RUkxbmJxd3JHYV9RMHpNc001bHA1MU5xQnRoX2k3SV94MHRtMlRvTTVzNk1iQW9tak5fRWhORURqT0l0SEFGcGtqYlN2OFhzc2NRVDN6eHc0SgZDZD",
  },
};

export const appId = domainValues[hostname].appId;
export const stripeImage =
  "img/" + domainValues[hostname].foldername + "/stripe_logo.png";
export const CountryTxt = domainValues[hostname].CountryTxt;
export const productNoImg = domainValues[hostname].productNoImg;
export const companyname = domainValues[hostname].companyname;
export const fbAppId = domainValues[hostname].fbAppId;
export const stripeEmail = domainValues[hostname].stripeEmail;
export const stripeReference = domainValues[hostname].stripeReference;
export const stripeDesc = domainValues[hostname].stripeDesc;
export const stripeCurrency = domainValues[hostname].stripeCurrency;
export const currencySybmol = domainValues[hostname].currencySybmol;

export const defaultoutlet = domainValues[hostname].defaultoutlet;
export const headermenu = domainValues[hostname].headermenu;
export const instaToken = domainValues[hostname].instaToken;
export const foldername = domainValues[hostname].foldername;
export const mainLogo =
  "/img/" + domainValues[hostname].foldername + "/logo.png";
export const noimage =
  "/img/" + domainValues[hostname].foldername + "/no-img-product.png";
export const productImg =
  "/img/" + domainValues[hostname].foldername + "/noimg-400x400.png";
export const noimage470 =
  "/img/" + domainValues[hostname].foldername + "/noimg-470x240.jpg";
export const baseUrl = domainValues[hostname].baseUrl;
