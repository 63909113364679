import React from "react";
import { render } from "react-dom";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Provider } from "react-redux";
import { getStore } from "./store";

import "./common/css/font-awesome.min.css";
import "./common/css/bootstrap.min.css";
import "./common/css/custom.css";
import "./common/css/responsive.css";
import "./common/css/slick.css";

import Home from "./components/Home/Home";
import Products from "./components/Products/Products";
import FavouriteProducts from "./components/Products/FavouriteProducts";
import ProductDetail from "./components/Products/ProductDetail";
import Checkout from "./components/Checkout/Checkout";
import Thankyou from "./components/Checkout/Thankyou";
import Payment from "./components/Payment/Payment";
import PaymentThankyou from "./components/Payment/Thankyou";
import Pages from "./components/Pages/Pages";
import Faq from "./components/Pages/Faq";
import Aboutus from "./components/Pages/Aboutus";
import ContactUs from "./components/Pages/ContactUs";
import Outlets from "./components/Pages/Outlets";
import Myaccount from "./components/Myaccount/Myaccount";
import Orders from "./components/Myaccount/Orders";
import Mypromotions from "./components/Myaccount/Mypromotions";
import Rewards from "./components/Myaccount/Rewards";
import Myvoucher from './components/Myaccount/Myvoucher';
import Account from "./components/Account/Account";
import Resetpassword from "./components/Account/Resetpassword";
import Scanqrcode from "./components/Dineqrcode/Scanqrcode";
import Booktable from "./components/Dineqrcode/Booktable";
import Myreservation from "./components/Myaccount/Myreservation";
import Referral from "./components/Myaccount/Referral";
import Rfcode from "./components/Layout/Rfcode";
import Catering from "./components/Catering/Catering";
import Packagecheckout from "./components/Catering/Packagecheckout";

import Reservation from "./components/Reservation/Reservation";
import Reservationsummary from "./components/Reservation/Reservationsummary";
import Reservationthankyou from "./components/Reservation/Reservationthankyou";
import Reservationsuggestion from "./components/Reservation/Reservationsuggestion";

import Membershipinfographic from "./components/Pages/Membershipinfographic";

import Logout from "./components/Myaccount/Logout";
import Refpage from "./components/Layout/Refpage";
import Page404 from "./Page404";

const store = getStore();

render(
  <Provider store={store}>
    <Router>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route
          path={"/products/:slugType/:slugValue/:proValue"}
          component={Products}
        />
        <Route path={"/products/:slugType/:slugValue"} component={Products} />
        <Route path="/products" component={Products} />
        <Route
          path={"/menu/:slugType/:slugValue/:proValue"}
          component={ProductDetail}
        />
        <Route path={"/menu/:slugType/:slugValue"} component={Products} />
        <Route path="/menu" component={Products} />
        <Route path="/favourite" component={FavouriteProducts} />
        <Route path="/package/checkout" component={Packagecheckout} />
        {/* <Route path="/package" component={Package} />  */}
        <Route path="/package" component={Catering} />
        <Route path="/checkout" component={Checkout} />
        <Route path="/scanqrcode" component={Scanqrcode} />
        <Route path="/quickbook/:tablenumber" component={Booktable} />
        <Route path="/thankyou/:orderId" component={Thankyou} />
        <Route path="/payment/thankyou" component={PaymentThankyou} />
        <Route path="/payment/:orderId/:paymentId" component={Payment} />
        <Route path="/membership" component={Membershipinfographic} />
        <Route path="/our-story" component={Pages} />
        <Route path="/terms-and-conditions" component={Pages} />
        <Route path="/privacy-policy" component={Pages} />
        <Route path="/promotions" component={Pages} />
        <Route path="/seasonal-promotions" component={Pages} />
        <Route path="/blog" component={Pages} />
        <Route path="/page/:page_slug" component={Pages} />
        <Route path="/faq" component={Faq} />
        <Route path="/about-us" component={Aboutus} />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/reservation" component={Reservation} />
        <Route path="/reservation-summary" component={Reservationsummary} />
        <Route path="/reservation-thankyou" component={Reservationthankyou} />
        <Route path="/locations" component={Outlets} />
        <Route path="/rewards" component={Rewards} />
        <Route path="/mypromotions" component={Mypromotions} />
        <Route path="/myorders/:tab" component={Orders} />
        <Route path="/myorders" component={Orders} />
        <Route path="/myreservations" component={Myreservation} />
        <Route path="/myvouchers" component={Myvoucher} />
        <Route path="/myaccount" component={Myaccount} />
        <Route path="/referral" component={Referral} />
        <Route path={"/rfcode/:slugtext"} component={Rfcode} />
        <Route path="/account/activation/:activationKey" component={Account} />
        <Route
          path="/reservation_suggest/:acceptreject/:resid"
          component={Reservationsuggestion}
        />
        <Route
          path="/account/resetpassword/:resetKey"
          component={Resetpassword}
        />
        <Route path="/logout" component={Logout} />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/:slugtext3"}
          component={Refpage}
        />
        <Route
          path={"/refpage/:slugtext/:slugtext1/:slugtext2/"}
          component={Refpage}
        />
        <Route path={"/refpage/:slugtext/:slugtext1/"} component={Refpage} />
        <Route path={"/refpage/:slugtext/"} component={Refpage} />
        <Route path={"/refpage/:slugtext"} component={Refpage} />
        <Route component={Page404} />
      </Switch>
    </Router>
  </Provider>,

  document.getElementById("root")
);
