/* eslint-disable */
import React, { Component } from "react";
import { Link } from "react-router-dom";
/* import modules */
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Slider from "react-slick";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { setMinutes, setHours, getDay, format } from "date-fns";
import Sidebar from "./Sidebar";
var Parser = require("html-react-parser");
import {
  fbAppId,
  baseUrl,
  appId,
  apiUrl,
  cateringId,
  deliveryId,
  companyname,
} from "../Helpers/Config";
import {
  addressFormat,
  stripslashes,
  showPriceValue,
  timeToConv12,
  showAlert,
} from "../Helpers/SettingHelper";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import Moment from "moment";
var qs = require("qs");

import {
  GET_ACTIVITYCOUNT,
  GET_REWARDEARNED,
  GET_REWARDREDEEM,
  GET_PRINTORDER,
  GET_ORDERHISTORY,
  GET_CATERINGHISTORY,
} from "../../actions";

import cookie from "react-cookies";
import scotterImg from "../../common/images/scotter-icon.png";
import mapImg from "../../common/images/map-icon.png";
import productImg from "../../common/images/noimg-400x400.jpg";

class Rewards extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cartItems: [],
      cartData: [],
      receiptDetails: [],
      activityPoints: {
        reward_ponits: "",
        reward_ponits_monthly: "",
        order_all: "",
        promotion: "",
      },
      status: "",
      status1: "",
      showearnitems: 10,
      showredeemitems: 10,
      order_all: 0,
      overall_orders: 0,
      reward_ponits: 0,
      CateringReceiptDetails: [],
      CateringCartItems: [],
      cart_brktime_list: [],
    };
    if (cookie.load("UserId") === undefined) {
      props.history.push("/");
    }
  }
  componentDidMount() {
    this.getActivityCounts();
    this.props.getRewardEarned(cookie.load("UserId"), this.state.showearnitems);
    this.props.getRewardRedeem(
      cookie.load("UserId"),
      this.state.showredeemitems
    );
    $("#dvLoading").fadeOut(2000);
  }

  /* for load more button -start */

  loadrewardearn() {
    var pageNext = this.state.showearnitems + 10;
    this.setState({ showearnitems: pageNext }, function () {
      this.loadereward();
    });
  }
  loadrewardredeem() {
    var pagepNext = this.state.showredeemitems + 10;
    this.setState({ showredeemitems: pagepNext }, function () {
      this.loadrreward();
    });
  }

  loadereward() {
    $(".load_more_data").append('<b class="gloading_img"></b>');
    this.props.getRewardEarned(cookie.load("UserId"), this.state.showearnitems);
  }
  loadrreward() {
    $(".load_more_data1").append('<b class="gloading_img"></b>');
    this.props.getRewardRedeem(
      cookie.load("UserId"),
      this.state.showredeemitems
    );
  }

  /* for load more button -end */

  /* Get Redeem Points Count History Details */
  getActivityCounts() {
    const inputKeys = ["reward", "overall_orders"];
    this.props.getActivityCount(JSON.stringify(inputKeys));
  }

  componentWillReceiveProps(nextProps) {
    /*activity count -start */
    if (Object.keys(nextProps.activitycount).length > 0) {
      if (nextProps.activitycount !== this.props.activitycount) {
        if (
          nextProps.activitycount[0].status &&
          nextProps.activitycount[0].result_set
        ) {
          this.setState({
            overall_orders:
              nextProps.activitycount[0].result_set.overall_orders,
            reward_ponits: nextProps.activitycount[0].result_set.reward_ponits,
          });
        }
      }
    } else {
      this.setState({ overall_orders: 0, reward_ponits: 0 });
    }
    /*activity count - end*/

    /* reward earned - start */
    if (nextProps.rewardearned !== this.props.rewardearned) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(2000);
      if (typeof nextProps.rewardearned[0].result_set !== "undefined") {
        this.setState({ pointsEarned: nextProps.rewardearned[0].result_set });
        this.setState({ status: "ok" });
        if (
          this.state.showearnitems >
          nextProps.rewardearned[0].common.total_records
        ) {
          $(".load_more_data").hide();
        } else {
          $(".load_more_data").show();
        }
      } else {
        $(".load_more_data").hide();
      }
    }
    /* reward earned - end */

    /* reward redeem - start */
    if (nextProps.rewardredeem !== this.props.rewardredeem) {
      $("b").removeClass("gloading_img");
      $("#dvLoading").fadeOut(2000);
      if (typeof nextProps.rewardredeem[0].result_set !== "undefined") {
        this.setState({ pointsRedeemed: nextProps.rewardredeem[0].result_set });
        this.setState({ status1: "ok" });
        if (
          this.state.showredeemitems >
          nextProps.rewardredeem[0].common.total_records
        ) {
          $(".load_more_data1").hide();
        } else {
          $(".load_more_data1").show();
        }
      } else {
        $(".load_more_data1").hide();
      }
    }
    /* reward redeem - end */

    /* view receipt - start */
    if (nextProps.orderhistory !== this.props.orderhistory) {
      if (nextProps.orderhistory[0].status === "ok") {
        this.setState({
          catrngCartItems: nextProps.orderhistory[0].result_set[0]["items"],
          catrngReceiptDetails: nextProps.orderhistory[0].result_set[0],
        });
        $("#dvLoading").fadeOut(1500);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_popup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        $("#dvLoading").fadeOut(2000);
      }
    }
    /* view receipt - end */

    if (nextProps.cateringhistory !== this.props.cateringhistory) {
      if (nextProps.cateringhistory[0].status === "ok") {
        console.log(nextProps.cateringhistory);
        this.setState(
          {
            CateringCartItems:
              nextProps.cateringhistory[0].result_set[0]["items"],
            CateringReceiptDetails: nextProps.cateringhistory[0].result_set[0],
          },
          function () {
            this.makeBrkTimeList();
          }.bind(this)
        );

        $("#dvLoading").fadeOut(5000);
        setTimeout(function () {
          $.magnificPopup.open({
            items: {
              src: ".receipt_cateringpopup",
            },
            type: "inline",
          });
        }, 1000);
      } else {
        this.setState({ CateringReceiptDetails: [], CateringCartItems: [] });
      }
    }

    /* print reward - start */
    if (nextProps.printorder !== this.props.printorder) {
      if (nextProps.printorder[0].status === "ok") {
        $("#dvLoading").fadeOut(2000);
        window.open(nextProps.printorder[0].pdf_url, "_blank");
      } else {
        if (nextProps.printorder[0].form_error) {
          showAlert("Error", nextProps.printorder[0].form_error);
        } else {
          showAlert("Error", nextProps.printorder[0].message);
        }
        $.magnificPopup.open({
          items: {
            src: ".alert_popup",
          },
          type: "inline",
        });
      }
    }
  }
  /* print reward - end */

  /* reward earned - start */
  __rewardListing() {
    if (this.state.status === "ok") {
      return this.state.pointsEarned.map((points, index) => (
        <div
          key={points.order_id}
          className={
            points.lh_from === "order" && points.order_primary_id !== ""
              ? "current_order"
              : "current_order custom_points_cls"
          }
        >
          {points.lh_from === "order" && points.order_primary_id !== "" ? (
            <div>
              <div className="myacc_head_sec">
                <div className="head_left">
                  <div className="head-group">
                    <h4>ORDER NO - {points.order_local_no}</h4>
                  </div>
                </div>
                <div className="head_right">
                  <div className="head-group">
                    <h4>{points.order_availability_name}</h4>
                  </div>
                </div>
              </div>

              <div className="order_details_body">
                <div className="delivery_total delivery_total_number">
                  <div className="expiry_on_lhs">
                    <div className="delivery_total_left">
                      <h4 className="checkoutDate">
                        {Moment(points.order_date).format("DD/MM/YYYY")}
                      </h4>
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      {/* Advanced Slot */}
                      <h4 className="checkoutTime">
                        {this.showOrdTimeSlot(points)}
                      </h4>
                    </div>
                  </div>
                  <div className="delivery_total_left delivery_total_right expiry_on_rhs">
                    <h4 className="checkoutDate">
                      Expiry On :
                      {Moment(points.lh_expiry_on).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                </div>

                <div className="order_amt_div">
                  <h3>
                    Order Amount <sup>$</sup>
                    {points.order_total_amount}
                  </h3>
                </div>
              </div>

              <div className="order_details_footer">
                <div className="order_amt">
                  <div className="order_amt-left">
                    <h3>{points.lh_status}</h3>
                  </div>
                  <div className="order_amt-right">
                    <h3 className="text-right">
                      {points.lh_credit_points} Points
                    </h3>
                  </div>
                </div>

                <div className="order_btns">
                  <a
                    href="javascript:void(0);"
                    onClick={this.printReceipt.bind(
                      this,
                      points.order_primary_id,
                      points.order_availability_id
                    )}
                    className="button print_invoice"
                    title=""
                  >
                    Print Invoice
                  </a>
                  <a
                    href="javascript:void(0);"
                    onClick={this.getReceipt.bind(
                      this,
                      points.order_id,
                      points.order_availability_id
                    )}
                    className="button view_recipt"
                    title=""
                  >
                    View Receipt
                  </a>
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="myacc_head_sec">
                <div className="head_left">
                  <div className="head-group">
                    <h4>Poinds Added By {companyname}</h4>
                  </div>
                </div>
                <div className="head_right">
                  <div className="head-group">
                    <h4>Admin</h4>
                  </div>
                </div>
              </div>

              <div className="order_details_body">
                <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h4 className="checkoutDate">
                      {Moment(points.lh_created_on).format("DD/MM/YYYY")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h4 className="checkoutTime">
                      {Moment(points.lh_created_on).format("h:mm A")}
                    </h4>
                  </div>
                </div>

                <div className="order_amt_div">
                  <h3>
                    {points.lh_reason !== ""
                      ? stripslashes(points.lh_reason)
                      : ""}
                  </h3>
                </div>
              </div>

              <div className="order_details_footer">
                <div className="order_amt">
                  <div className="order_amt-left">
                    <h3>{points.lh_status}</h3>
                  </div>
                  <div className="order_amt-right">
                    <h3 className="text-right">
                      {points.lh_credit_points} Points
                    </h3>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }
  /* reward earned - end */

  /* reward redeem - start */

  __rewardRedeemListing() {
    if (this.state.status1 === "ok") {
      return this.state.pointsRedeemed.map((points, index) => (
        <div key={points.order_id} className="current_order">
          <div className="myacc_head_sec">
            <div className="head_left">
              <div className="head-group">
                <h4>ORDER NO - {points.order_local_no}</h4>
              </div>
            </div>
            <div className="head_right">
              <div className="head-group">
                <h4>{points.order_availability_name}</h4>
              </div>
            </div>
          </div>

          <div className="order_details_body">
            <div className="delivery_total delivery_total_number">
              <div className="delivery_total_left">
                <h4 className="checkoutDate">
                  {Moment(points.order_date).format("DD/MM/YYYY")}
                </h4>
              </div>
              <div className="delivery_total_left delivery_total_right">
                <h4 className="checkoutTime">
                  {Moment(points.order_date).format("h:mm A")}
                </h4>
              </div>
            </div>

            <div className="order_amt_div">
              <h3>
                Order Amount <sup>$</sup>
                {points.order_total_amount}
              </h3>
            </div>
          </div>

          <div className="order_details_footer">
            <div className="order_amt">
              <div className="order_amt-left">
                <h3>{points.lh_redeem_status}</h3>
              </div>
              <div className="order_amt-right">
                <h3 className="text-right">{points.lh_redeem_point} Points</h3>
              </div>
            </div>

            <div className="order_btns">
              <a
                href="javascript:void(0);"
                onClick={this.printReceipt.bind(
                  this,
                  points.order_primary_id,
                  points.order_availability_id
                )}
                className="button print_invoice"
                title=""
              >
                Print Invoice
              </a>
              <a
                href="javascript:void(0);"
                onClick={this.getReceipt.bind(
                  this,
                  points.order_id,
                  points.order_availability_id
                )}
                className="button view_recipt"
                title=""
              >
                View Receipt
              </a>
            </div>
          </div>
        </div>
      ));
    } else {
      return <div className="no-recrds-found">No records found</div>;
    }
  }
  /* reward redeem - end */

  makeBrkTimeList() {
    var cartDetailsSate = this.state.CateringReceiptDetails;
    var cartItems = this.state.CateringCartItems;
    var cartBrkTmList = [];
    if (
      Object.keys(cartDetailsSate).length > 0 &&
      Object.keys(cartItems).length > 0
    ) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      var breaktimeCount = cartDetailsSate.order_breaktime_count;
      if (breaktimeEnable === "Yes" && parseInt(breaktimeCount) > 0) {
        var tempKeyArr = Array();
        var tempKey = 0;
        for (var key in cartItems) {
          var chkKey = cartItems[key].item_breaktime_indexflag;
          if (!(chkKey in tempKeyArr)) {
            tempKeyArr[chkKey] = tempKey;
            var tempItemArr = Array();
            var tempItemLst = [];
            var intVl = tempKey + 1;
            var time_lable = "Break " + intVl;
            tempItemArr["cart_detaile"] = {
              break_time_lable: time_lable,
              break_time_index: cartItems[key].item_breaktime_indexflag,
              break_time_start: cartItems[key].item_breaktime_started,
              break_time_end: cartItems[key].item_breaktime_ended,
            };
            tempItemLst[0] = cartItems[key];
            tempItemArr["cart_items"] = tempItemLst;
            cartBrkTmList[tempKey] = tempItemArr;
            tempKey++;
          } else {
            var extKey = tempKeyArr[chkKey];
            cartBrkTmList[extKey]["cart_items"].push(cartItems[key]);
          }
        }
      }
    }

    this.setState({ cart_brktime_list: cartBrkTmList });

    setTimeout(function () {
      if ($(".mCustomScrollbar").length > 0) {
        $(".mCustomScrollbar").mCustomScrollbar();
      }
    }, 800);
  }

  getReceipt(orderId, availabilityId) {
    $("#dvLoading").fadeIn();
    var params =
      "&customer_id=" + cookie.load("UserId") + "&order_id=" + orderId;
    if (availabilityId === cateringId) {
      this.props.getCateringHistory(params);
    } else {
      this.props.getOrderHistory(params);
    }
  }

  printReceipt(orderprimaryId, availabilityId) {
    $("#dvLoading").fadeIn();
    var availabilityIdNew =
      availabilityId === cateringId ? cateringId : deliveryId;
    this.props.getPrintOrder(orderprimaryId, availabilityIdNew);
  }

  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }
  render() {
    var settingsMyAcc = {
      infinite: false,
      slidesToShow: 4,
      slidesToScroll: 4,
      initialSlide: 2,
      responsive: [
        {
          breakpoint: 1191,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 3,
            infinite: false,
          },
        },
        {
          breakpoint: 900,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
            infinite: false,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
            infinite: false,
          },
        },
      ],
    };

    /*setTimeout(function () {
		$('#dvLoading').remove();
		}, 500);*/
    return (
      <div className="myacc-main-div">
        <Header />
        <div className="common-inner-blckdiv">
          <div className="common-inner-banner">
            <h3>My Account</h3>
            <p class="acc-para">
              {" "}
              My Account Dashboard allows you to view your recent activities,
              check your reward points and update account information.
            </p>
          </div>
        </div>
        <div className="innersection_wrap myadmin_wrap">
          <div className="mainacc_menusec">
            {/* container div - start */}
            <div className="container">
              <div className="mainacc_menuout"></div>
              <div className="mainacc_menucontent">
                <div className="main_tabsec">
                  <div className="order-tab-section account_sec rewards-newdesign">
                    <Sidebar pageName="rewards" />
                    <div class="box_in accsec_right">
                      <div class="account_sec">
                        <div className="accsec_right">
                          <div className="mainacc_mobrow">
                            <div className="congrats">
                              <div className="congrats-inner">
                                <h3>Your Current Reward Points</h3>
                                <h2>{this.state.reward_ponits || 0} Points</h2>
                              </div>
                            </div>
                            <div className="tab_sec main_tabsec_inner">
                              <div className="myacc_filter">
                                <div className="tab_sec filter_tabsec">
                                  <ul className="nav nav-tabs text-center">
                                    <li className="active">
                                      <a
                                        data-toggle="tab"
                                        href="#tab-id-inn5"
                                        aria-expanded="true"
                                      >
                                        <span>REWARDS EARNED</span>
                                      </a>
                                    </li>
                                    <li className="">
                                      <a
                                        data-toggle="tab"
                                        href="#tab-id-inn6"
                                        aria-expanded="false"
                                      >
                                        <span>REWARDS REDEEMED</span>
                                      </a>
                                    </li>
                                  </ul>
                                  <div className="tab-content">
                                    <div
                                      id="tab-id-inn5"
                                      className="tab-pane fade active in"
                                    >
                                      <h4 className="tab_mobtrigger inner_tab_border active"></h4>
                                      <div className="tab_mobrow filter_tabin">
                                        <div className="order-delivery">
                                          <div className="ord-body">
                                            <div className="cur-order-body reward-list-body">
                                              <div className="myacc_order_details">
                                                {this.__rewardListing(
                                                  this.state.pointsEarned
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="load_more_div">
                                        <button
                                          className="load_more_data"
                                          style={{ display: "none" }}
                                          onClick={this.loadrewardearn.bind(
                                            this
                                          )}
                                        >
                                          Load More
                                        </button>
                                      </div>
                                    </div>
                                    <div
                                      id="tab-id-inn6"
                                      className="tab-pane fade"
                                    >
                                      <h4 className="tab_mobtrigger inner_tab_border"></h4>
                                      <div className="tab_mobrow filter_tabin">
                                        <div className="cur-order-body reward-list-body">
                                          <div className="myacc_order_details">
                                            {this.__rewardRedeemListing(
                                              this.state.pointsRedeemed
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                      <div className="load_more_div">
                                        <button
                                          className="load_more_data1"
                                          style={{ display: "none" }}
                                          onClick={this.loadrewardredeem.bind(
                                            this
                                          )}
                                        >
                                          Load More
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* container div - end */}
          </div>
        </div>

        <Footer />
        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_popup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewreceipt
              details={this.state.catrngReceiptDetails}
              cartItems={this.state.catrngCartItems}
            />
          </div>
        </div>

        <div
          id="receipt-popup"
          className="white-popup mfp-hide popup_sec receipt_cateringpopup catering_receipt_popup"
        >
          <div className="pouup_in">
            <Viewcateringreceipt
              details={this.state.CateringReceiptDetails}
              cartItems={this.state.CateringCartItems}
              brktimeList={this.state.cart_brktime_list}
            />
          </div>
        </div>

        <div id="dvLoading"></div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    activitycount: state.activitycount,
    rewardearned: state.rewardearned,
    rewardredeem: state.rewardredeem,
    orderhistory: state.orderhistory,
    cateringhistory: state.cateringhistory,
    printorder: state.printorder,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getActivityCount: (getObject) => {
      dispatch({ type: GET_ACTIVITYCOUNT, getObject });
    },
    getRewardEarned: (customer_id, limit) => {
      dispatch({ type: GET_REWARDEARNED, customer_id, limit });
    },
    getRewardRedeem: (customer_id, limit) => {
      dispatch({ type: GET_REWARDREDEEM, customer_id, limit });
    },
    getOrderHistory: (params) => {
      dispatch({ type: GET_ORDERHISTORY, params });
    },
    getCateringHistory: (historyparams) => {
      dispatch({ type: GET_CATERINGHISTORY, historyparams });
    },
    getPrintOrder: (orderprimaryId, availabilityId) => {
      dispatch({ type: GET_PRINTORDER, orderprimaryId, availabilityId });
    },
  };
};
Rewards.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};
export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(Rewards)
);

class Viewreceipt extends React.Component {
  constructor(props) {
    super(props);
  }

  loadItems(cartItems) {
    if (cartItems.length > 0) {
      return cartItems.map((item, index) => (
        <div className="cart_row oreder_itm_row" key={index}>
          <div className="row oreder-row-inv">
            <div className="col-xs-7 cart_left">
              <div className="cart_info">
                <h4>
                  {stripslashes(item.item_name)} X {item.item_qty}
                </h4>

                {this.loadModifierItems(
                  "Component",
                  item.modifiers,
                  item.set_menu_component
                )}
              </div>

              {item.item_specification !== "" && (
                <p className="help-block">
                  {stripslashes(item.item_specification)}
                </p>
              )}

              {/*} <p className ="help-block">Special instruction for individual product items ...</p>{*/}
            </div>
            <div className="col-xs-5 cart_right text-right">
              <div className="cart_price">
                <p>${parseFloat(item.item_total_amount).toFixed(2)}</p>
              </div>
            </div>
          </div>
        </div>
      ));
    }
  }

  /* this function used to load modifer items */
  loadModifierItems(itemType, modifiers, combo) {
    var len = modifiers.length;
    var comboLen = combo.length;
    var html = '<div class="cart_extrainfo">';

    if (len > 0) {
      for (var i = 0, length = len; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        html +=
          "<p><b>" +
          stripslashes(modName) +
          ":</b></p><p> " +
          stripslashes(modval) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    } else if (comboLen > 0) {
      for (var i = 0, length = comboLen; i < length; i++) {
        var comboName = combo[i]["menu_component_name"];
        var comboVal = this.showComboProducts(combo[i]["product_details"]);

        html +=
          "<p><b>" +
          comboName +
          ":</b></p><p> " +
          comboVal +
          " " +
          this.showComboModifiers(combo[i]["product_details"][0]["modifiers"]) +
          "</p> ";
      }
      html += "</div>";
      var reactElement = Parser(html);
      return reactElement;
    }
  }

  /* show combo products  list */
  showComboProducts(combos) {
    var lenCombo = combos.length;
    var html = " ";
    if (lenCombo > 0) {
      for (var r = 0, lengthCombo = lenCombo; r < lengthCombo; r++) {
        var comboPro = combos[r]["menu_product_name"];
        var comboQty = combos[r]["menu_product_qty"];
        var comboPrice = combos[r]["menu_product_price"];
        var newPrice = comboPrice > 0 ? " (+" + comboPrice + ")" : "";
        html += "<p>" + comboQty + " X " + comboPro + newPrice + " </p> ";
      }
      return html;
    }
    return "";
  }

  /* this function used to show combo modifieirs list */
  showComboModifiers(modifiers) {
    var lenMod = modifiers.length;
    var html = "";
    if (lenMod > 0) {
      html = "<div >";
      for (var i = 0, length = lenMod; i < length; i++) {
        var modName = modifiers[i]["order_modifier_name"];
        var modval = modifiers[i]["modifiers_values"][0]["order_modifier_name"];
        var modValPrice =
          modifiers[i]["modifiers_values"][0]["order_modifier_price"];
        var newModValPrice = modValPrice > 0 ? " (+" + modValPrice + ")" : "";
        html +=
          "<p><b>" +
          modName +
          ":</b></p><p> " +
          modval +
          newModValPrice +
          "</p> ";
      }
      html += "</div>";
    }
    return html;
  }
  /* Advanced Slot */
  showOrdTimeSlot(orderDetail) {
    var ordTmSlt = Moment(orderDetail.order_date).format("hh:mm A");
    if (orderDetail.order_is_timeslot === "Yes") {
      var slotTime1 =
        orderDetail.order_pickup_time_slot_from !== ""
          ? orderDetail.order_pickup_time_slot_from.split(":")
          : Array();
      var slotTime2 =
        orderDetail.order_pickup_time_slot_to !== ""
          ? orderDetail.order_pickup_time_slot_to.split(":")
          : Array();
      if (
        Object.keys(slotTime1).length > 0 &&
        Object.keys(slotTime2).length > 0
      ) {
        var startTimeVal = parseInt(slotTime1[0]);
        var startMinitVal = parseInt(slotTime1[1]);
        var strdatevalobj = new Date();
        strdatevalobj.setHours(startTimeVal);
        strdatevalobj.setMinutes(startMinitVal);

        var endTimeVal = parseInt(slotTime2[0]);
        var endMinitVal = parseInt(slotTime2[1]);
        var enddatevalobj = new Date();
        enddatevalobj.setHours(endTimeVal);
        enddatevalobj.setMinutes(endMinitVal);

        ordTmSlt =
          format(strdatevalobj, "p") + " - " + format(enddatevalobj, "p");
      }
    }

    return ordTmSlt;
  }

  render() {
    const { details, cartItems } = this.props;
    if (details !== undefined) {
      return (
        <div className="Viewreceipt">
          <div className="tnk-you">
            <div className="tnk-header">
              <div className="tnk-detail">
                <div className="tnk-order">
                  <h3>Order No - {details.order_local_no}</h3>
                  <p>
                    Order placed at :{" "}
                    {Moment(details.order_created_on).format(
                      "DD-MM-YYYY hh:mm A"
                    )}
                    <br></br>Pay by : {details.order_method_name}
                  </p>
                </div>
                <h2>YOUR ORDER DETAILS</h2>
              </div>
              <div className="tnk-delivery">
                {details.order_availability_id === deliveryId ? (
                  <div className="delivery_total">
                    <div className="delivery_total_left">
                      <img src={scotterImg} />
                      <h3>Delivery From</h3> <span>{details.outlet_name}</span>
                      {details.order_outlet_category_id !== "2" && (
                        <span>
                          {addressFormat(
                            details.outlet_unit_number1,
                            details.outlet_unit_number2,
                            details.outlet_address_line1,
                            details.outlet_address_line2,
                            details.outlet_postal_code
                          )}
                        </span>
                      )}
                    </div>
                    <div className="delivery_total_left delivery_total_right">
                      <img src={mapImg} />
                      <h3>Deliver TO</h3>{" "}
                      <span>{details.order_customer_address_line1}</span>{" "}
                      <span>
                        Singapore {details.order_customer_postal_code}
                      </span>{" "}
                      <span>
                        {details.order_customer_unit_no1 != "" &&
                        details.order_customer_unit_no2 != ""
                          ? details.order_customer_unit_no1 +
                            "-" +
                            details.order_customer_unit_no2
                          : ""}
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="delivery_total pickup-order-div">
                    <div className="delivery_total_left">
                      <h3>Pickup Location</h3>
                      <span>{details.outlet_name}</span>
                      <span>
                        {addressFormat(
                          details.outlet_unit_number1,
                          details.outlet_unit_number2,
                          details.outlet_address_line1,
                          details.outlet_address_line2,
                          details.outlet_postal_code
                        )}
                      </span>
                    </div>
                  </div>
                )}

                <div className="delivery_total delivery_total_number">
                  <div className="delivery_total_left">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      Date
                    </h2>
                    <h4 className="checkoutDate">
                      {Moment(details.order_date).format("DD-MM-YYYY")}
                    </h4>
                  </div>
                  <div className="delivery_total_left delivery_total_right">
                    <h2>
                      {details.order_availability_id === deliveryId
                        ? "Delivery"
                        : "Pickup"}{" "}
                      time
                    </h2>
                    <h4 className="checkoutTime">
                      {/* Advanced Slot */}
                      {this.showOrdTimeSlot(details)}
                    </h4>
                  </div>
                </div>
                <div className="order-items-maindiv">
                  {this.loadItems(cartItems)}
                </div>

                <div className="cart_footer tnk_cart_footer">
                  <div className="cart_row">
                    <p className="text-uppercase">SUBTOTAL</p>
                    <span>${details.order_sub_total}</span>
                  </div>

                  {parseFloat(details.order_discount_amount) > 0 && (
                    <div className="cart_row">
                      <p className="text-uppercase">
                        {details.order_discount_type === "redeem"
                          ? "Discount (-)"
                          : "Promocode (-)"}
                      </p>
                      <span>${details.order_discount_amount}</span>
                    </div>
                  )}

                  {parseFloat(details.order_delivery_charge) > 0 && (
                    <div className="cart_row">
                      <p className="text-uppercase">Delivery Charges</p>
                      <span>
                        $
                        {(
                          parseFloat(details.order_delivery_charge) +
                          parseFloat(details.order_additional_delivery)
                        ).toFixed(2)}
                      </span>
                    </div>
                  )}

                  {details.order_tax_calculate_amount > 0 && (
                    <div className="cart_row  gst-row">
                      <p className="text-uppercase">
                        GST({details.order_tax_charge}%)
                      </p>
                      <span>${details.order_tax_calculate_amount}</span>
                    </div>
                  )}

                  <div className="cart_row cart_footer_totrow grant-total-cls">
                    <p className="text-uppercase">Total</p>
                    <span>${details.order_total_amount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

class Viewcateringreceipt extends Component {
  constructor(props) {
    super(props);
  }

  getCartDetList(details, cartItems, brktimeList) {
    var cartDetailsSate = details;
    var cartBrktimeLst = brktimeList;
    if (Object.keys(cartDetailsSate).length > 0) {
      var breaktimeEnable = cartDetailsSate.order_breaktime_enable;
      if (breaktimeEnable === "Yes" && Object.keys(cartBrktimeLst).length > 0) {
        return this.cartBrkTimeList(details, cartItems, brktimeList);
      } else {
        return this.cartListOnly(details, cartItems, brktimeList);
      }
    }
  }

  cartListOnly(details, cartItems, brktimeList) {
    return (
      <div className="panel panel-default">
        <div
          className="panel-heading act"
          role="tab"
          id="headingTb0"
          onClick={this.handlerActTab.bind(this, 0)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded="true"
              href="#collapse0"
              className=""
            >
              Order Item Details
            </a>
          </h4>
        </div>
        <div
          id="collapse0"
          className="panel-collapse collapse mCustomScrollbar in"
          aria-expanded="true"
          href="#collapse0"
        >
          <div className="panel-body">{this.cartItemlist(cartItems)}</div>
        </div>
      </div>
    );
  }

  cartBrkTimeList(details, cartItems, brktimeList) {
    var cartBrktimeLst = brktimeList;

    const cartBrktmhtml = cartBrktimeLst.map((cartBrk, indx) => (
      <div className="panel panel-default" key={indx}>
        <div
          className={indx === 0 ? "panel-heading act" : "panel-heading"}
          role="tab"
          id={"headingTb" + indx}
          onClick={this.handlerActTab.bind(this, indx)}
        >
          <h4 className="panel-title">
            <a
              role="button"
              data-toggle="collapse"
              data-parent="#accordion"
              aria-expanded={indx === 0 ? "true" : "false"}
              href={"#collapse" + indx}
              className={indx === 0 ? "" : "collapsed"}
            >
              {cartBrk["cart_detaile"].break_time_lable} :{" "}
              <span className="hr">
                {timeToConv12(cartBrk["cart_detaile"].break_time_start)}
              </span>
            </a>
          </h4>
        </div>
        <div
          id={"collapse" + indx}
          className={
            indx === 0
              ? "panel-collapse collapse mCustomScrollbar in"
              : "panel-collapse collapse mCustomScrollbar"
          }
          aria-expanded={indx === 0 ? "true" : "false"}
          href={"#collapse" + indx}
        >
          <div className="panel-body">
            {this.cartItemlist(cartBrk["cart_items"])}
          </div>
        </div>
      </div>
    ));
    return cartBrktmhtml;
  }

  handlerActTab(indx) {
    var ariaAxp = $("#headingTb" + indx)
      .find("a")
      .attr("aria-expanded");
    if (ariaAxp === "true") {
      $("#headingTb" + indx).addClass("act");
    } else {
      $("#headingTb" + indx).removeClass("act");
    }
    $(".panel-heading")
      .not($("#headingTb" + indx))
      .removeClass("act");
  }

  cartItemlist(itemLst) {
    if (Object.keys(itemLst).length > 0) {
      const cartlsthtml = itemLst.map((cartLst, indx1) => (
        <div key={indx1}>
          {/* package div start */}
          <div className="package">
            <div className="row package_cnt">
              <div className="col-sm-4 col-xs-4">
                <a href="javascript:void(0)">
                  <span className="title">{cartLst.item_name}</span>
                </a>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="qtybxs"> {cartLst.item_qty} pax </div>
              </div>
              <div className="col-sm-4 col-xs-4">
                <div className="amt">
                  <span>{showPriceValue(cartLst.item_total_amount)}</span>
                </div>
              </div>
            </div>

            {this.showModifiers(cartLst.modifiers)}
          </div>
          {/* package div end */}

          <div className="brline"></div>

          {Object.keys(cartLst.addons_setup).length > 0 ? (
            <div className="addon">
              <h4>ADD-ONS</h4>
              {this.addonsSetupLst(cartLst)}
            </div>
          ) : (
            ""
          )}

          <div className="brline"></div>

          {Object.keys(cartLst.setup).length > 0 ? this.setupDet(cartLst) : ""}

          <div className="brline"></div>

          {cartLst.item_specification !== "" ? this.specialNotes(cartLst) : ""}
        </div>
      ));
      return cartlsthtml;
    }
  }

  showModifiers(mdfLst) {
    if (Object.keys(mdfLst).length > 0) {
      const mdflsthtml = mdfLst.map((mdfLt, indx3) => (
        <div key={indx3} className="package-list">
          <p className="one">{mdfLt.order_modifier_name}</p>
          <p className="two">{this.showMdfValues(mdfLt.modifiers_values)}</p>
        </div>
      ));
      return mdflsthtml;
    }
  }

  showMdfValues(mdfValLst) {
    var mdfValueText = "";
    if (Object.keys(mdfValLst).length > 0) {
      var tempVl = Object.keys(mdfValLst).length - 1;
      for (var key in mdfValLst) {
        var cartmdfprice =
          parseFloat(mdfValLst[key].order_modifier_price) > 0
            ? " ( " + showPriceValue(mdfValLst[key].order_modifier_price) + " )"
            : "";
        mdfValueText +=
          parseInt(tempVl) === parseInt(key)
            ? stripslashes(mdfValLst[key].order_modifier_name) + cartmdfprice
            : stripslashes(mdfValLst[key].order_modifier_name) +
              cartmdfprice +
              ", ";
      }
    }
    return mdfValueText;
  }

  addonsSetupLst(cartLstDat) {
    var addonsSet = cartLstDat.addons_setup;
    const addonhtml = addonsSet.map((addonSt, indx4) => (
      <div key={indx4} className="row addon_list">
        <div className="col-sm-6 col-xs-9">
          <h5>
            <a href="javascript:void(0)">
              <span className="title">{addonSt.as_setup_title}</span>
            </a>
          </h5>
          {this.addonsSetupValues(addonSt.addons_setup_values)}
        </div>
        <div className="col-sm-6 col-xs-3">
          <div className="amt">
            <span>{showPriceValue(addonSt.as_setup_amount)}</span>
          </div>
        </div>
      </div>
    ));
    return addonhtml;
  }

  addonsSetupValues(addonsVal) {
    if (Object.keys(addonsVal).length > 0) {
      const addonValhtml = addonsVal.map((addonVl, indx5) => (
        <p key={indx5}>
          {addonVl.asv_setup_val_title} ({addonVl.asv_setup_val_qty}X)
        </p>
      ));
      return addonValhtml;
    }
  }

  setupDet(cartLstDat) {
    var setupDt = cartLstDat.setup;
    var setupLbl = "";
    var setupPrice = 0;

    for (var key in setupDt) {
      setupLbl = setupDt[key].os_setup_type;
      setupPrice += parseFloat(setupDt[key].os_setup_total_price);
    }

    return (
      <div className="buffet_setup">
        <div className="row">
          <div className="col-sm-6 col-xs-9">
            <h5>
              <a href="javascript:void(0)">
                <span className="title">{setupLbl} Setup</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-3">
            <div className="amt">
              <span>{showPriceValue(setupPrice)}</span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">{this.setupList(setupDt)}</div>
        </div>
      </div>
    );
  }

  setupList(setupDats) {
    if (Object.keys(setupDats).length > 0) {
      const setupDathtml = setupDats.map((setupVl, indx6) => (
        <p key={indx6}>
          {setupVl.os_setup_name} : {setupVl.os_setup_description}
        </p>
      ));
      return setupDathtml;
    }
  }

  specialNotes(cartLstDat) {
    var special_not = cartLstDat.item_specification;

    return (
      <div className="buffet_setup" style={{ paddingTop: "0px" }}>
        <div className="row">
          <div className="col-sm-6 col-xs-8">
            <h5>
              <a href="">
                <span className="title">Special Notes</span>
              </a>
            </h5>
          </div>
          <div className="col-sm-6 col-xs-4"></div>
        </div>
        <div className="row">
          <div className="col-sm-10 col-xs-10">
            <p>{special_not}</p>
          </div>
        </div>
      </div>
    );
  }

  cartHallData(details, cartItems, brktimeList) {
    var cartDetailsMn = details;
    if (Object.keys(cartDetailsMn).length > 0) {
      if (cartDetailsMn.order_venue_type === "hall") {
        return (
          <div className="panel panel-default">
            <div
              className="panel-heading"
              role="tab"
              id="headingTbH1"
              onClick={this.handlerActTab.bind(this, "H1")}
            >
              <h4 className="panel-title">
                <a
                  role="button"
                  data-toggle="collapse"
                  data-parent="#accordion"
                  aria-expanded="false"
                  href="#collapseH1"
                  className="collapsed"
                >
                  Catering Hall Details
                </a>
              </h4>
            </div>
            <div
              id="collapseH1"
              className="panel-collapse fixed-height-cls collapse mCustomScrollbar"
              aria-expanded="false"
              href="#collapseH1"
            >
              <div className="panel-body">
                <div className="buffet_setup" style={{ padding: "0px" }}>
                  <div className="row">
                    <div className="col-sm-6 col-xs-8">
                      <h5>
                        <a href="javascript:void(0)">
                          <span className="title">
                            {cartDetailsMn.order_hall_name}
                          </span>
                        </a>
                      </h5>
                    </div>
                    <div className="col-sm-6 col-xs-4">
                      <div className="amt">
                        <span>
                          {showPriceValue(cartDetailsMn.order_hall_charges)}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      }
    }
  }

  render() {
    const { details, cartItems, brktimeList } = this.props;

    return (
      <div className="Viewreceipt">
        <div className="tnk-you">
          <div className="tnk-header">
            <div className="tnk-detail">
              <h2>YOUR ORDER DETAILS</h2>
              <div className="tnk-order">
                <h3>Order No - {details.order_local_no}</h3>
                <p>
                  Order placed at :{" "}
                  {Moment(details.order_created_on).format(
                    "DD-MM-YYYY hh:mm A"
                  )}
                </p>
              </div>
            </div>
          </div>

          <div className="tnk-delivery">
            <div className="delivery_total">
              <div className="delivery_total_left">
                <img src={scotterImg} />
                <h3>Catering Handling By</h3> <span>{details.outlet_name}</span>
                {details.order_outlet_category_id !== "2" && (
                  <span>
                    {addressFormat(
                      details.outlet_unit_number1,
                      details.outlet_unit_number2,
                      details.outlet_address_line1,
                      details.outlet_address_line2,
                      details.outlet_postal_code
                    )}
                  </span>
                )}
              </div>
              <div className="delivery_total_left delivery_total_right">
                <img src={mapImg} />
                <h3>Catering Location</h3>
                {details.order_hall_name !== "" ? (
                  <span>{details.order_hall_name}</span>
                ) : (
                  ""
                )}
                <span>
                  {addressFormat(
                    details.order_customer_unit_no1,
                    details.order_customer_unit_no2,
                    details.order_customer_address_line1,
                    details.order_customer_address_line2,
                    details.order_customer_postal_code
                  )}
                </span>
              </div>
            </div>

            <div className="delivery_total delivery_total_number">
              <div className="delivery_total_left">
                <h2>Event Date</h2>
                <h4 className="checkoutDate">
                  {Moment(details.order_date).format("DD-MM-YYYY")}
                </h4>
              </div>
              <div className="delivery_total_left delivery_total_right">
                <h2>Event time</h2>
                <h4 className="checkoutTime">
                  {Moment(details.order_date).format("hh:mm A")}
                </h4>
              </div>
            </div>

            <div className="order_details">
              <div
                className="panel-group"
                id="accordion"
                role="tablist"
                aria-multiselectable="true"
              >
                {this.getCartDetList(details, cartItems, brktimeList)}
                {this.cartHallData(details, cartItems, brktimeList)}

                {details.order_remarks !== "" && (
                  <div className="remark_notesec text-left">
                    <h4>Remarks</h4>
                    <p>{details.order_remarks}</p>
                  </div>
                )}

                {details.order_customer_send_gift === "Yes" && (
                  <>
                    <div className="remark_notesec text-left">
                      <h4>Recipient Name</h4>
                      <p>{details.order_recipient_name}</p>
                    </div>
                    <div className="remark_notesec text-left">
                      <h4>Recipient Contact Number</h4>
                      <p>{details.order_recipient_contact_no}</p>
                    </div>
                    {details.order_gift_message !== "" &&
                      details.order_gift_message !== null && (
                        <div className="remark_notesec text-left">
                          <h4>Gift Message</h4>
                          <p>{details.order_gift_message}</p>
                        </div>
                      )}
                  </>
                )}
              </div>
            </div>

            <div className="cart_footer tnk_cart_footer">
              <div className="cart_row">
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">SUBTOTAL</p>
                  </div>
                  <div className="col-xs-5 text-right">
                    <span>${details.order_sub_total}</span>
                  </div>
                </div>
              </div>

              {parseFloat(details.order_discount_amount) > 0 && (
                <div className="cart_row">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">
                        {details.order_discount_type === "redeem"
                          ? "Discount (-)"
                          : "Promocode (-)"}
                      </p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_discount_amount}</span>
                    </div>
                  </div>
                </div>
              )}

              {details.order_tax_calculate_amount > 0 && (
                <div className="cart_row">
                  <div className="row">
                    <div className="col-xs-7">
                      <p className="text-uppercase">
                        GST({details.order_tax_charge}%)
                      </p>
                    </div>
                    <div className="col-xs-5 text-right">
                      <span>${details.order_tax_calculate_amount}</span>
                    </div>
                  </div>
                </div>
              )}

              <div className="cart_row cart_footer_totrow grant-total-cls">
                <div className="row">
                  <div className="col-xs-7">
                    <p className="text-uppercase">Total</p>
                  </div>
                  <div className="col-xs-5 text-right">
                    <span>${details.order_total_amount}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
